import {
  FETCH_PRODUCT_REPORTS_ERROR,
  FETCH_PRODUCT_REPORTS_START,
  FETCH_PRODUCT_REPORTS_SUCCESS
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  reports: [],
  reportsPagination: {},
};
const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REPORTS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_PRODUCT_REPORTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        reports: action.payload.docs,
        reportsPagination: {
          ...state.reportsPagination,
          ...action.payload,
        }
      };
      break;
    case FETCH_PRODUCT_REPORTS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default leadReducer;
