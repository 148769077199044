import React from "react";
import { Col } from "reactstrap";

const MamProfilesBodyCol = ({ value, colNum }) => {
  return (
    <Col xs={colNum} className="p-1">
      {value}
    </Col>
  );
};

export default MamProfilesBodyCol;
