export const FETCH_MARKUPS_START = "FETCH_MARKUPS_START";
export const FETCH_MARKUPS_SUCCESS = "FETCH_MARKUPS_SUCCESS";
export const MARKUP_EDIT_START = "MARKUP_EDIT_START";
export const MARKUP_EDIT_SUCCESS = "MARKUP_EDIT_SUCCESS";
export const EDIT_MARKUP_CLEAR = "EDIT_MARKUP_CLEAR";
export const DELETE_MARKUP_START = "DELETE_MARKUP_START";
export const DELETE_MARKUP_SUCCESS = "DELETE_MARKUP_SUCCESS";
export const ADD_MARKUP_START = "ADD_MARKUP_START";
export const ADD_MARKUP_SUCCESS = "ADD_MARKUP_SUCCESS";
export const MARKUP_API_ERROR = "MARKUP_API_ERROR";
export const ADD_MARKUP_CLEAR = "ADD_MARKUP_CLEAR";
export const ADD_MARKUP_ERROR = "ADD_MARKUP_ERROR";
export const DELETE_MARKUP_CLEAR = "DELETE_MARKUP_CLEAR";
export const FETCH_MARKUP_DETAILS_START = "FETCH_MARKUP_DETAILS_START";