import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
import * as goldApi from "../../../apis/gold";
import { fetchTransactionsError, fetchTransactionsSuccess } from "./actions";
import { FETCH_TRANSACTIONS_START } from "./actionTypes";

function* fetchTransactionStart(params = {}) {
  try {
    const data = yield call(goldApi.getTransactions, params);
    yield put(fetchTransactionsSuccess(data));
  } catch (error) {
    yield put(fetchTransactionsError(error));
  }
}

function* productSaga() {
  yield takeEvery(FETCH_TRANSACTIONS_START, fetchTransactionStart);
}

export default productSaga;