import {
  FETCH_PRODUCT_REQUESTS_ERROR,
  FETCH_PRODUCT_REQUESTS_START,
  FETCH_PRODUCT_REQUESTS_SUCCESS,
  UPDATE_PRODUCT_REQUEST_ERROR,
  UPDATE_PRODUCT_REQUEST_START,
  UPDATE_PRODUCT_REQUEST_SUCCESS
} from "./actionTypes";


const initialState = {
  loading: false,
  error: "",
  requests: [],
  requestsPagination: {},
  clearingCounter: 0,
};
const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUESTS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_PRODUCT_REQUESTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        requests: action.payload.docs,
        requestsPagination: {
          ...action.payload,
          docs: undefined,
        },
      };
      break;
    case FETCH_PRODUCT_REQUESTS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case UPDATE_PRODUCT_REQUEST_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PRODUCT_REQUEST_SUCCESS:
      state = {
        ...state,
        loading: false,
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case UPDATE_PRODUCT_REQUEST_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default productsReducer;
