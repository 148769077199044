

import qs from "qs";
import * as axiosHelper from "../api_helper";

export const getDeliveryFees = async({ payload }) => {
  const data = await axiosHelper.get(`/delivery-fees?${qs.stringify(payload)}`);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data.result;
};

export const addDeliveryFee = async(payload) => {
  const data = await axiosHelper.post("/delivery-fees", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const editDeliveryFee = async(payload ) => {
  const id = payload.id;
  delete payload.id;
  const data = await axiosHelper.patch(`/delivery-fees/${id}`, payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};
