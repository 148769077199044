
export const FETCH_CUSTOMERS_COUNTRIES_START = "FETCH_CUSTOMERS_COUNTRIES_START";
export const FETCH_CUSTOMERS_COUNTRIES_END = "FETCH_CUSTOMERS_COUNTRIES_END";

export const FETCH_CUSTOMERS_STATS_START = "FETCH_CUSTOMERS_STATS_START";
export const FETCH_CUSTOMERS_STATS_END = "FETCH_CUSTOMERS_STATS_END";

export const FETCH_LEAD_STAGES_STATS_START = "FETCH_LEAD_STAGES_STATS_START";
export const FETCH_LEAD_STAGES_STATS_SUCCESS = "FETCH_LEAD_STAGES_STATS_SUCCESS";
export const FETCH_LEAD_STAGES_STATS_FAILED = "FETCH_LEAD_STAGES_STATS_FAILED";
