import React, { useEffect, useState } from "react";
import {
  Card, CardBody, CardTitle, CardSubtitle, Table
} from "reactstrap";
import { getRequestStats } from "apis/dashboard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProductRequestsStats = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  useEffect(async () => {
    const st = await getRequestStats();
    setStatus(st);
  }, []);
  return (
    <React.Fragment>
      <Card className="card-animate">
        <CardBody>
          <CardTitle className="color-primary">{t("Product Requests")}</CardTitle>
          <CardSubtitle className="mb-3">
            {t("Product Requests Stats")}
          </CardSubtitle>
          <div className="table-responsive">
            <Table className="table table-borderless mb-0">
              <thead>
                <tr className="text-center">
                  <th></th>
                  <th>{t("Pending")}</th>
                  <th>{t("In Progress")}</th>
                  <th>{t("Rejected")}</th>
                  <th>{t("Approved")}</th>
                  <th>{t("Delivered")}</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <th className="text-start" scope="row">
                    <Link to={"/requests/delivery"}>
                      {t("Product Delivery")}
                    </Link>
                  </th>
                  <td>
                    <Link to={`/requests/delivery?status=${"PENDING"}`}>
                      {(status?.goldObj?.DELIVERY?.PENDING ?? 0) + (status?.goldObj?.STORAGE_TO_DELIVERY?.PENDING ?? 0) ?? 0}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/delivery?status=${"IN_PROGRESS"}`}>
                      {(status?.goldObj?.DELIVERY?.IN_PROGRESS ?? 0) + (status?.goldObj?.STORAGE_TO_DELIVERY?.IN_PROGRESS ?? 0)}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/delivery?status=${"REJECT"}`}>
                      {(status?.goldObj?.DELIVERY?.REJECT ?? 0 ) + (status?.goldObj?.STORAGE_TO_DELIVERY?.REJECT ?? 0)}
                    </Link>
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    <Link to={`/requests/delivery?status=${"DELIVERED"}`}>
                      {(status?.goldObj?.DELIVERY?.DELIVERED ?? 0) + (status?.goldObj?.STORAGE_TO_DELIVERY?.DELIVERED ?? 0)}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th className="text-start" scope="row">
                    <Link to={"/requests/storage"}>
                      {t("Product Storage")}
                    </Link>
                  </th>
                  <td>
                    <Link to={`/requests/storage?status=${"PENDING"}`}>
                      {status?.goldObj?.STORAGE?.PENDING ?? 0}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/storage?status=${"IN_PROGRESS"}`}>
                      {status?.goldObj?.STORAGE?.IN_PROGRESS ?? 0}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/storage?status=${"APPROVE"}`}>
                      {status?.goldObj?.STORAGE?.REJECT ?? 0}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/storage?status=${"APPROVE"}`}>
                      {status?.goldObj?.STORAGE?.APPROVE ?? 0}
                    </Link>
                  </td>
                  <td>
                    -
                  </td>
                </tr>
                <tr>
                  <th className="text-start" scope="row">
                    <Link to={"/requests/sell-back"}>
                      {t("Product Sell Back")}
                    </Link>
                  </th>
                  <td>
                    <Link to={`/requests/sell-back?status=${"PENDING"}`}>
                      {status?.goldObj?.SELL_BACK?.PENDING ?? 0}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/sell-back?status=${"IN_PROGRESS"}`}>
                      {status?.goldObj?.SELL_BACK?.IN_PROGRESS ?? 0}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/sell-back?status=${"REJECT"}`}>
                      {status?.goldObj?.SELL_BACK?.REJECT ?? 0}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/requests/sell-back?status=${"APPROVE"}`}>
                      {status?.goldObj?.SELL_BACK?.APPROVE ?? 0}
                    </Link>
                  </td>
                  <td>
                    -
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ProductRequestsStats;