import logo from "assets/images/brands/brand-main-sm.png";
import auth from "assets/images/logos/320x120.png";
import sm from "assets/images/logos/30x40.png";

export const clientName = "Guldzon";
export const developedBy = "Guldzon";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const crmUrl = process.env.REACT_APP_CP_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Guldzon";
export const sidebarLogo = auth;
export const sidebarLogoSm = sm;
export const mainLogo = logo;
export const authLogo = auth;
