import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";


import {
  showErrorNotification,
  showSuccessNotification,
} from "store/notifications/actions";
import {
  addDeliveryFeesError,
  addDeliveryFeesSuccess,
  editDeliveryFeesError,
  editDeliveryFeesSuccess,
  fetchDeliveryFeesError,
  fetchDeliveryFeesSuccess
} from "./actions";
import {
  addDeliveryFee,
  editDeliveryFee,
  getDeliveryFees
} from "apis/delivery-fees";
import {
  ADD_DELIVERY_FEES_START,
  EDIT_DELIVERY_FEES_START,
  FETCH_DELIVERY_FEES_START
} from "./actionTypes";

//Include Both Helper File with needed methods

function* fetchDeliveryFees(params = {}) {
  try {
    const data = yield call(getDeliveryFees, params);
    yield put(fetchDeliveryFeesSuccess(data));
  } catch (error) {
    yield put(fetchDeliveryFeesError(error));
  }
}

function* addDeliveryFees({ payload }) {
  try {
    const data = yield call(addDeliveryFee, payload);

    const { result } = data;
    yield put(addDeliveryFee(result));
    yield put(showSuccessNotification("Delivery Fee added successfully"));
    yield put(addDeliveryFeesSuccess(result));
  } catch (error) {
    yield put(showErrorNotification(error.message || "Something went wrong"));
    yield put(addDeliveryFeesError(error));
  }
}

function* editDeliveryFees({ payload }) {
  try {
    const data = yield call(editDeliveryFee, payload);
    const { result } = data;
    yield put(showSuccessNotification("Delivery Fee updated successfully"));
    yield put(editDeliveryFeesSuccess(result));
  } catch (error) {
    yield put(showErrorNotification(error.message || "Something went wrong"));
    yield put(editDeliveryFeesError(error));
  }
}

function* goldSaga() {
  yield takeEvery(FETCH_DELIVERY_FEES_START, fetchDeliveryFees);
  yield takeEvery(ADD_DELIVERY_FEES_START, addDeliveryFees);
  yield takeEvery(EDIT_DELIVERY_FEES_START, editDeliveryFees);
}

export default goldSaga;
