import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import TableLoader from "components/Common/TableLoader";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { fetchConversionRateStart, updateProductRequest } from "store/actions";

export default function ResellModal({
  request,
  isOpen,
  toggle,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, conversionRate }  = useSelector((state) => state.conversionReducer);

  useEffect(() => {
    if (request?.currency !== request?.products?.currency) {
      dispatch(fetchConversionRateStart({
        from: request?.products?.currency,
        to: request?.currency,
      }));
    }
  }, [request]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader
        toggle={toggle}
      >
        <h5 className="modal-title" id="exampleModalLabel">
          Resell
        </h5>
      </ModalHeader>
      <ModalBody>
        <AvForm
          onValidSubmit={(e, v) => {
            dispatch(updateProductRequest(
              {
                id: request?._id,
                status: "APPROVE",
                amount: v.amount,
              }
            ));
            toggle();
          }}
        >
          <Row>
            <Col
              className="col-12 text-center"
            >
              <h5>Product Name: {request?.products?.title}</h5>
              <h5>Currency user selected : {request?.currency}</h5>
              <h5>Total Quantity : {request?.quantity}</h5>
            </Col>
            <Col
              className="col-12 text-center py-3"
            >
              {
                loading ? <TableLoader/>  : request?.currency !== request?.products?.currency ? (
                  <div>
                    <h5>
                      Original Product Price : {request?.products?.price} {request?.products?.currency}
                    </h5>
                    <h5>
                      Converted Product Resell Price : {(parseFloat(request?.products?.sellPrice) * parseFloat(conversionRate))?.toFixed(2)} {request?.currency}
                    </h5>
                  </div>
                ) :  <h5>
                Product Price : {request?.products?.price} {request?.products?.currency}
                </h5>
              }
            </Col>
            <Col
              className="col-12"
            >
              <AvField
                name="amount"
                label={t(`Amount per Quantity (${request?.currency})`)}
                placeholder={t(`Enter Amount per Quantity (${request?.currency})`)}
                type="number"
                value={(parseFloat(request?.products?.sellPrice) * parseFloat(conversionRate))?.toFixed(2)}
                errorMessage={t("Enter You need to enter amount")}
                min={1}
                validate={{ required: { value: true } }}
              />
            </Col>
          </Row>
          <ModalFooter
            className="justify-content-around"
          >
            <Button
              className="btn btn-secondary"
              data-dismiss="modal"
              type="button"
              color="danger"
              onClick={toggle}
            >
              Close
            </Button>
            <Button className="btn btn-primary" type="submit" color="success">
              Approve Transaction
            </Button>
          </ModalFooter>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
