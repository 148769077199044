import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import {
  showErrorNotification,
  showSuccessNotification,
} from "store/notifications/actions";

//Include Both Helper File with needed methods
import * as goldApi from "../../../apis/gold";
import {
  fetchProductRequestsError,
  fetchProductRequestsSuccess,
  updateProductRequestError,
  updateProductRequestSuccess
} from "./actions";
import { FETCH_PRODUCT_REQUESTS_START, UPDATE_PRODUCT_REQUEST_START } from "./actionTypes";

function* fetchProductRequests({ payload = {} }) {
  try {
    const data = yield call(goldApi.getProductRequests, { params: payload });
    yield put(fetchProductRequestsSuccess(data));
  } catch (error) {
    yield put(fetchProductRequestsError(error));
  }
}

function* updateProductRequest({ payload }) {
  try {
    const data = yield call(goldApi.updateProductRequest, payload);
    const { result } = data;
    yield put(showSuccessNotification("Product Request Updated Successfully"));
    yield put(updateProductRequestSuccess(result));
  } catch (error) {
    yield put(updateProductRequestError(error));
    yield put(showErrorNotification(error.message));
  }
}

function* deliverySaga() {
  yield takeEvery(FETCH_PRODUCT_REQUESTS_START, fetchProductRequests);
  yield takeEvery(UPDATE_PRODUCT_REQUEST_START, updateProductRequest);
}

export default deliverySaga;
