import {
  call,
  put,
  takeEvery,
  delay
} from "redux-saga/effects";
import { 
  FETCH_MM_WITHDRAW_REQUESTED,
  ADD_MM_WITHDRAW_REQUESTED,
  APPROVE_MM_WITHDRAW,
  APPROVE_MM_WITHDRAW_SUCCESS,
  REJECT_MM_WITHDRAW,
  REJECT_MM_WITHDRAW_SUCCESS
} from "./actionTypes";
import {
  fetchMMWithdrawSuccess,
  fetchMMWithdrawFail,
  addMMWithdrawSuccess,
  addMMWithdrawFail,
  addMMWithdrawClear,
  addMMWithdrawErrorClear
} from "./actions";
import * as mmWithdrawApis from "apis/mmWithdrawal";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function * fetchMMWithdraws(params){
  try {
    const data = yield call(mmWithdrawApis.getMMWithdraws, params);
    yield put(fetchMMWithdrawSuccess(data));
  } catch (err){
    yield put(fetchMMWithdrawFail(err.message));
  }
}

function * addMMWithdraw(params){
  try {
    const data = yield call(mmWithdrawApis.postMMWithdraw, params);
    yield put(addMMWithdrawSuccess(data));
    yield put(showSuccessNotification("Withdraw Added Successfully"));
    yield put(addMMWithdrawClear());
  } catch (err){
    yield put(addMMWithdrawFail(err.message));
    yield delay(5000);
    yield put(addMMWithdrawErrorClear());
  }
}
function * approveMMWithdraw({ payload:{ id, customerId } }) {
  try {
    const res = yield call(mmWithdrawApis.approveMMWithdraw, id, customerId);
    if (res.status){
      yield put({
        type: APPROVE_MM_WITHDRAW_SUCCESS,
        payload: id 
      });
    }
    yield put(showSuccessNotification("MM Withdraw approved successfully"));
  } catch (error) {
    yield put(showErrorNotification(error.message));
  }
}

function * rejectMMWithdraw({ payload:{ id, customerId } }) {
  try {
    const res = yield call(mmWithdrawApis.rejectMMWithdraw, id, customerId);
    if (res.status){
      yield put({
        type: REJECT_MM_WITHDRAW_SUCCESS,
        payload: id 
      });
    }
    yield put(showSuccessNotification("MM Withdraw rejected successfully"));
  } catch (error) {
    yield put(showErrorNotification(error.message));
  }
}

function * moneyManagementWithdrawSaga(){
  yield takeEvery(FETCH_MM_WITHDRAW_REQUESTED, fetchMMWithdraws);
  yield takeEvery(ADD_MM_WITHDRAW_REQUESTED, addMMWithdraw);
  yield takeEvery(APPROVE_MM_WITHDRAW, approveMMWithdraw);
  yield takeEvery(REJECT_MM_WITHDRAW, rejectMMWithdraw);
}
export default moneyManagementWithdrawSaga;