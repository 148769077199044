import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

const MamProfilesHeaderCol = (props) => {
  const { label, colNum } = props;
  const { t } = useTranslation();
  return (
    <Col xs={colNum} className="border border-dark p-1">
      <b>{t(label)}</b>
    </Col>
  );
};

export default MamProfilesHeaderCol;
