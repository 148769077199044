import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
import { fetchReportsError, fetchReportsSuccess } from "./actions";
import * as api from "apis/gold";
import { FETCH_PRODUCT_REPORTS_START } from "./actionTypes";

// Login Redux States
function* fetchReport(payload) {
  try {
    const response = yield call(api.getReport, payload);
    yield put(fetchReportsSuccess(response));
  } catch (error) {
    yield put(fetchReportsError(error));
  }
}

function* goldSaga() {
  yield takeEvery(FETCH_PRODUCT_REPORTS_START, fetchReport);
}

export default goldSaga;
