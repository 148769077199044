import React from "react";
import { NavLink } from "react-router-dom";

// i18n
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function MainNavigation({ clientId, isIb, isLead, t, isDemo, isSp, isInvestor, hasAffiliate = false, hasProfile }) {
  const { profileMetaInfo } = useSelector((state) => state.Profile);
  const tabsArr = ({ clientDetails = {} }) => {
    let route = "";
    if (isDemo){
      route = "demo";
    } else if (isLead) {
      route = "leads";
    } else {
      route = "clients";
    }
    const tabs = [
      {
        name: "Profile",
        url: `/${route}/${clientId}/profile`,
        hidden: !clientDetails.profile,
      },
      {
        name: "Bank Accounts",
        url: `/${route}/${clientId}/bank`,
        hidden: !clientDetails.bankAccounts || isLead || isDemo,
      },
      {
        name: "Documents",
        url: `/${route}/${clientId}/documents`,
        hidden: !clientDetails.documents || isLead || isDemo,
      },
      {
        name: "Trading Accounts",
        url: `/${route}/${clientId}/trading-accounts`,
        hidden: !clientDetails.tradingAccounts,
      },
      {
        name: "Wallets",
        url: `/${route}/${clientId}/wallets`,
        hidden: !clientDetails?.wallets || isDemo,
      },
      {
        name: "Transactions",
        url: `/${route}/${clientId}/transactions`,
        hidden: !clientDetails.transactions || isLead || isDemo,
      },
      {
        name: "Converts",
        url: `/${route}/${clientId}/converts`,
        hidden: !clientDetails.converts,
      },
      {
        name: "Orders",
        url: `/${route}/${clientId}/orders`,
        hidden: !clientDetails.orders,
      },
      {
        name: "Logs",
        url: `/${route}/${clientId}/logs`,
        hidden: !clientDetails.logs,
      },
      {
        name: "Notes",
        url: `/${route}/${clientId}/notes`,
        hidden: !clientDetails.notes,
      },
      {
        name: "Security",
        url: `/${route}/${clientId}/security`,
        hidden: !clientDetails.security,
      },
      // {
      //   name: "Affiliate",
      //   url: `/clients/${clientId}/affiliate`,
      //   hidden: !hasAffiliate,
      // },
    ];

    // if (isInvestor) {
    //   tabs.push({
    //     name: "Investor Profiles",
    //     url: `/clients/${clientId}/investor-profiles`,
    //     // hidden: !clientDetails.mamProfiles,
    //   });
    // }

    if (isIb) {
      tabs.push(
        {
          name: "Partnership",
          url: `/${route}/${clientId}/partnership`,
          hidden: !clientDetails.partnership,
        },
        {
          name: "Referral",
          url: `/${route}/${clientId}/referral`,
          hidden: true,
        },
      );
    }

    if (isSp || isIb) {
      tabs.push(
        {
          name: "Statement",
          url: `/${route}/${clientId}/statement`,
          hidden: !clientDetails.statement,
        });
    }
    if (isIb)
      tabs.push({
        name: "Partnership",
        url: `/clients/${clientId}/partnership`,
        hidden: !clientDetails.partnership,
      });

    if (isSp && hasProfile)
      tabs.push({
        name: "MAM Profiles",
        url: `/clients/${clientId}/mam-profiles`,
        // hidden: !clientDetails.mamProfiles,
      });

    return tabs;
  };

  return (
    <React.Fragment>
      <div className="navbar-header mb-5">
        <div className="container-fluid">
          <ul className="nav-tabs-custom nav-justified nav nav-tabs page-menues">
            {tabsArr(profileMetaInfo || {})
              .filter((item) => !item.hidden)
              .map((obj, index) => (
                <li className={"nav-item " + `item-${index}`} key={index}>
                  <NavLink
                    to={obj.url}
                    className={(isActive) =>
                      "nav-link" + (!isActive ? " unselected" : "")
                    }
                  >
                    {t(obj.name)}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(MainNavigation);
