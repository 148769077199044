import {
  call,
  put,
  takeEvery,
  delay
} from "redux-saga/effects";
import { 
  FETCH_MM_DEPOSITS_REQUESTED,
  ADD_MM_DEPOSIT_REQUESTED,
  APPROVE_MM_DEPOSIT,
  APPROVE_MM_DEPOSIT_SUCCESS,
  REJECT_MM_DEPOSIT,
  REJECT_MM_DEPOSIT_SUCCESS,

  FETCH_CLIENT_SUBSCRIPTIONS_REQUESTED,

} from "./actionTypes";
import {
  fetchMMDepositsSuccess,
  fetchMMDepositsFail,
  addMMDepositSuccess,
  addMMDepositFail,
  addMMDepositClear,
  addMMDepositErrorClear,
  fetchClientSubscriptionsSuccess
} from "./actions";
import * as mmDepositApis from "apis/mmDeposit";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function * fetchMMDeposits(params){
  try {
    const data = yield call(mmDepositApis.getMMDeposits, params);
    yield put(fetchMMDepositsSuccess(data));
  } catch (err){
    yield put(fetchMMDepositsFail(err.message));
  }
}

function * fetchClientSubscriptions(params){
  try {
    const data = yield call(mmDepositApis.getMMDeposits, params);
    yield put(fetchClientSubscriptionsSuccess(data));
  } catch (err){
    yield put(fetchMMDepositsFail(err.message));
  }
}

function * addMMDeposit(params){
  try {
    const data = yield call(mmDepositApis.postMMDeposit, params);
    yield put(addMMDepositSuccess(data));
    yield put(showSuccessNotification("Deposit Added Successfully"));
    yield put(addMMDepositClear());
  } catch (err){
    yield put(addMMDepositFail(err.message));
    yield delay(5000);
    yield put(addMMDepositErrorClear());
  }
}
function * approveMMDeposit({ payload:{ id, customerId } }) {
  try {
    const res = yield call(mmDepositApis.approveMMDeposit, id, customerId);
    if (res.status){
      yield put({
        type: APPROVE_MM_DEPOSIT_SUCCESS,
        payload: id 
      });
    }
    yield put(showSuccessNotification("MM Deposit approved successfully"));
  } catch (error) {
    yield put(showErrorNotification(error.message));
  }
}

function * rejectMMDeposit({ payload:{ id, customerId } }) {
  try {
    const res = yield call(mmDepositApis.rejectMMDeposit, id, customerId);
    if (res.status){
      yield put({
        type: REJECT_MM_DEPOSIT_SUCCESS,
        payload: id 
      });
    }
    yield put(showSuccessNotification("MM Deposit rejected successfully"));
  } catch (error) {
    yield put(showErrorNotification(error.message));
  }
}

function * moneyManagementDepositSaga(){
  yield takeEvery(FETCH_MM_DEPOSITS_REQUESTED, fetchMMDeposits);
  yield takeEvery(ADD_MM_DEPOSIT_REQUESTED, addMMDeposit);
  yield takeEvery(APPROVE_MM_DEPOSIT, approveMMDeposit);
  yield takeEvery(REJECT_MM_DEPOSIT, rejectMMDeposit);
  yield takeEvery(FETCH_CLIENT_SUBSCRIPTIONS_REQUESTED, fetchClientSubscriptions);
}
export default moneyManagementDepositSaga;