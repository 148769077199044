
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const ADD_USERS_START = "ADD_USERS_START";
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
export const ADD_USERS_ERROR = "ADD_USERS_ERROR";
export const ADD_USER_CLEAR = "ADD_USER_CLEAR";

export const FETCH_USERS_ROLES_START = "FETCH_USERS_ROLES_START";
export const FETCH_USERS_ROLES_SUCCESS = "FETCH_USERS_ROLES_SUCCESS";
export const FETCH_USERS_ROLES_ERROR = "FETCH_USERS_ROLES_ERROR";

export const EDIT_USERS_PASS_START = "EDIT_USERS_PASS_START";
export const EDIT_USERS_START = "EDIT_USERS_START";
export const EDIT_USERS_DONE = "EDIT_USERS_DONE";
export const EDIT_USERS_ERROR = "EDIT_USERS_ERROR";
export const EDIT_USER_CLEAR = "EDIT_USER_CLEAR";

export const DELETE_USERS_START = "DELETE_USERS_START";
export const DELETE_USERS_DONE = "DELETE_USERS_DONE";
export const GET_ASSIGNED_USERS_START = "GET_ASSIGNED_USERS_START";
export const GET_ASSIGNED_USERS_SUCCESS = "GET_ASSIGNED_USERS_sUCCESS";
export const GET_ASSIGNED_USERS_FAIL = "GET_ASSIGNED_USERS_FAIL";
export const ASSIGN_AGENT_START = "ASSIGN_AGENT_START";
export const ASSIGN_AGENT_COMPLETE = "ASSIGN_AGENT_COMPLETE";
export const ASSIGN_AGENT_CLEAR = "ASSIGN_AGENT_CLEAR";
export const ASSIGN_AGENT_ERROR = "ASSIGN_AGENT_ERROR";

export const USER_DISABLE_2FA_START = "USER_DISABLE_2FA_START";
export const USER_DISABLE_2FA_SUCCESS = "USER_DISABLE_2FA_SUCCESS";
export const USER_DISABLE_2FA_FAIL = "USER_DISABLE_2FA_FAIL";

export const EDIT_TARGET_START = "EDIT_TARGET_START";
export const EDIT_TARGET_SUCCESS = "EDIT_TARGET_SUCCESS";
export const EDIT_TARGET_FAIL = "EDIT_TARGET_FAIL";
