import * as axiosHelper from "./api_helper";
import qs from "qs";

export async function fetchMamPamAPI(payload) {
  try {
    const result = await axiosHelper.get(`/mam-profile/profiles?${qs.stringify(payload)}`);
    if (!result.isSuccess) {
      throw new Error(result.message);
    }
    return result.result;
  } catch (error) {
    throw new Error(error?.message ?? "Something went wrong");
  }
}

export async function createProfileAPI(payload) {
  try {
    const result = await axiosHelper.post("/requests/mam-profile", payload);
    if (!result.isSuccess) {
      throw new Error(result.message);
    }
    return result.result;
  } catch (error) {
    throw new Error(error?.message ?? "Something went wrong");
  }
}

export async function linkMamAPI(payload) {
  try {
    const result = await axiosHelper.post("/requests/mam-profile/link", payload);
    if (!result.isSuccess) {
      throw new Error(result.message);
    }
    return result.result;
  } catch (error) {
    throw new Error(error?.message ?? "Something went wrong");
  }
}
export async function unlinkMamAPI(payload) {
  try {
    const result = await axiosHelper.post("/requests/mam-profile/unlink", payload);
    if (!result.isSuccess) {
      throw new Error(result.message);
    }
    return result.result;
  } catch (error) {
    throw new Error(error?.message ?? "Something went wrong");
  }
}

export async function fetchProfileBySlaveAccountId(payload) {
  try {
    const result = await axiosHelper.get(`/mam-profile/profiles?${qs.stringify(payload)}`);
    if (!result.isSuccess) {
      throw new Error(result.message);
    }
    return result.result;
  } catch (error) {
    throw new Error(error?.message ?? "Something went wrong");
  }
}

export async function getFeesDeductedAPI(payload) {
  try {
    const result = await axiosHelper.get(`/mam-profile/fees-deducted?${qs.stringify(payload)}`);
    if (!result.isSuccess) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    throw new Error(error?.message ?? "Something went wrong");
  }
}