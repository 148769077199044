import {
  call, put, takeEvery
} from "redux-saga/effects";
// Login Redux States
import {
  FETCH_STRATEGY_START,
  ADD_STRATEGY_START,
  EDIT_STRATEGY_START,
  DELETE_STRATEGY_START,
  CHANGE_STATUS_STRATEGY_START
} from "./actionTypes";
import {
  fetchStrategySuccess, fetchStrategyError, addStrategySuccess, addStrategyError, addStrategyClear, editStrategyDone, editStrategyClear, deleteStrategyDone, changeStatusDone
} from "./actions";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

//Include Both Helper File with needed methods
import * as strategyApi from "../../apis/strategy";


function * fetchStrategy(params){
  try {
    const data = yield call(strategyApi.getStrategy, params);    
    yield put(fetchStrategySuccess(data));
  }
  catch (error){
    yield put(fetchStrategyError(error));
  } 

  
}

function * addStrategy(params){
  try {
    const data = yield call(strategyApi.addStrategy, params);
    
    
    const { result } = data;
    yield put(addStrategySuccess(result));
    yield put(showSuccessNotification("Strategy added successfully"));
    // yield delay(2000);
    yield put(addStrategyClear());
  }
  catch (error){
    yield put(addStrategyError(error));
    // yield delay(2000);
    // yield put(addStrategyClear());
  } 

  
}

function * editStrategy(params){
  try {
    yield call(strategyApi.editStrategy, params);
    
    
    // const { result } = data;
    yield put(editStrategyDone({
      result: params.payload.values,
      id: params.payload.id  
    }));
    yield put(showSuccessNotification("Strategy updated successfully"));

    // yield delay(2000);
    yield put(editStrategyClear());
  }
  catch (error){
    yield put(editStrategyDone({ error: error.message }));
    // yield delay(2000);
    // yield put(editStrategyClear());
  } 

  
}

function * deleteStrategy(params) {
  try {
    const data = yield call(strategyApi.deleteStrategy, params);
    const { result } = data;
    yield put(deleteStrategyDone({
      result,
      id: params.payload 
    }));
    yield put(showSuccessNotification("Strategy deleted successfully"));

  }
  catch (error){
    yield put(deleteStrategyDone({ error: error.message }));
    yield put(showErrorNotification(error.message));

  } 

  
}

function * changeStatusStrategy(params) {
  try {
    const data = yield call(strategyApi.changeStatusStrategy, params);
    const { result } = data;
    yield put(changeStatusDone({
      result,
      id: params.payload.id,
      index: params.payload.index,

    }));
    yield put(showSuccessNotification("Strategy Status Changed successfully"));

  }
  catch (error){
    yield put(changeStatusDone({
      error: error.message,
      index: params.payload.index,
    }));
    yield put(showErrorNotification(error.message));

  } 

  
}

function* authSaga() {
  yield takeEvery(FETCH_STRATEGY_START, fetchStrategy);
  yield takeEvery(ADD_STRATEGY_START, addStrategy);
  yield takeEvery(EDIT_STRATEGY_START, editStrategy);
  yield takeEvery(DELETE_STRATEGY_START, deleteStrategy);
  yield takeEvery(CHANGE_STATUS_STRATEGY_START, changeStatusStrategy);
}

export default authSaga;
