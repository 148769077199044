
export const FETCH_DELIVERY_FEES_START = "FETCH_DELIVERY_FEES_START";
export const FETCH_DELIVERY_FEES_SUCCESS = "FETCH_DELIVERY_FEES_SUCCESS";
export const FETCH_DELIVERY_FEES_ERROR = "FETCH_DELIVERY_FEES_ERROR";

export const ADD_DELIVERY_FEES_START = "ADD_DELIVERY_FEES_START";
export const ADD_DELIVERY_FEES_SUCCESS = "ADD_DELIVERY_FEES_SUCCESS";
export const ADD_DELIVERY_FEES_ERROR = "ADD_DELIVERY_FEES_ERROR";

export const EDIT_DELIVERY_FEES_START = "EDIT_DELIVERY_FEES_START";
export const EDIT_DELIVERY_FEES_SUCCESS = "EDIT_DELIVERY_FEES_SUCCESS";
export const EDIT_DELIVERY_FEES_ERROR = "EDIT_DELIVERY_FEES_ERROR";
