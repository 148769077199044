export const FETCH_CONVERSION_RATE_START = "FETCH_CONVERSION_RATE_START";
export const FETCH_CONVERSION_RATE_SUCCESS = "FETCH_CONVERSION_RATE_SUCCESS";

export const FETCH_CONVERSION_RATES_START = "FETCH_CONVERSION_RATES_START";
export const FETCH_CONVERSION_RATES_SUCCESS = "FETCH_CONVERSION_RATES_SUCCESS";
export const FETCH_CONVERSION_RATES_ERROR = "FETCH_CONVERSION_RATES_ERROR";

export const ADD_CONVERSION_RATES_START = "ADD_CONVERSION_RATES_START";
export const ADD_CONVERSION_RATES_SUCCESS = "ADD_CONVERSION_RATES_SUCCESS";
export const ADD_CONVERSION_RATES_ERROR = "ADD_CONVERSION_RATES_ERROR";

export const EDIT_CONVERSION_RATES_START = "EDIT_CONVERSION_RATES_START";
export const EDIT_CONVERSION_RATES_SUCCESS = "EDIT_CONVERSION_RATES_SUCCESS";
export const EDIT_CONVERSION_RATES_ERROR = "EDIT_CONVERSION_RATES_ERROR";
