import {
  ADD_DELIVERY_FEES_ERROR,
  ADD_DELIVERY_FEES_START,
  ADD_DELIVERY_FEES_SUCCESS,
  EDIT_DELIVERY_FEES_ERROR,
  EDIT_DELIVERY_FEES_START,
  EDIT_DELIVERY_FEES_SUCCESS,
  FETCH_DELIVERY_FEES_ERROR,
  FETCH_DELIVERY_FEES_START,
  FETCH_DELIVERY_FEES_SUCCESS
} from "./actionTypes";

export const fetchDeliveryFees = (params) => {
  return {
    type: FETCH_DELIVERY_FEES_START,
    payload: params,
  };
};

export const fetchDeliveryFeesSuccess = (data) => {
  return {
    type: FETCH_DELIVERY_FEES_SUCCESS,
    payload: data,
  };
};

export const fetchDeliveryFeesError = (error) => {
  return {
    type: FETCH_DELIVERY_FEES_ERROR,
    payload: error,
  };
};

export const addDeliveryFees = (params) => {
  return {
    type: ADD_DELIVERY_FEES_START,
    payload: params,
  };
};

export const addDeliveryFeesSuccess = (data) => {
  return {
    type: ADD_DELIVERY_FEES_SUCCESS,
    payload: data,
  };
};

export const addDeliveryFeesError = (error) => {
  return {
    type: ADD_DELIVERY_FEES_ERROR,
    payload: error,
  };
};

export const editDeliveryFees = (params) => {
  return {
    type: EDIT_DELIVERY_FEES_START,
    payload: params,
  };
};

export const editDeliveryFeesSuccess = (data) => {
  return {
    type: EDIT_DELIVERY_FEES_SUCCESS,
    payload: data,
  };
};

export const editDeliveryFeesError = (error) => {
  return {
    type: EDIT_DELIVERY_FEES_ERROR,
    payload: error,
  };
};