import { all, fork } from "redux-saga/effects";

import productsSaga from "./products/saga";
import transactions from "./transactions/saga";
import requests from "./requests/saga";
import transfers from "./transfer/saga";
import reports from "./report/saga";
import deliveryFees from "./deliveryFees/saga";

export default function* goldSaga() {
  yield all([
    fork(productsSaga),
    fork(transactions),
    fork(requests),
    fork(transfers),
    fork(reports),
    fork(deliveryFees)
  ]);
}