export const FETCH_ASSETS_START = "FETCH_ASSETS_START";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const ADD_NEW_SYMBOL = "ADD_NEW_SYMBOL";
export const ADD_NEW_SYMBOL_SUCCESS = "ADD_NEW_SYMBOL_SUCCESS";
export const ADD_SYMBOL_CLEAR = "ADD_SYMBOL_CLEAR";
export const EDIT_SYMBOL_START = "EDIT_SYMBOL_START";
export const EDIT_SYMBOL_DONE = "EDIT_SYMBOL_DONE";
export const EDIT_SYMBOL_CLEAR = "EDIT_SYMBOL_CLEAR";

export const DELETE_SYMBOL_START = "DELETE_SYMBOL_START";
export const DELETE_SYMBOL_DONE = "DELETE_SYMBOL_DONE";

export const API_ERROR = "API_ERROR";