import { getClients } from "apis/client";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import React from "react";

export default function SearchableInvestor({
  investor, setInvestor
}) {
  return (
    <SearchableComponent
      placeholder={("Select Investor")}
      label={"Investor"}
      name={"investor"}
      onChange={(e) => {
        e?.value ? setInvestor(e.value) : setInvestor(e);
      }}
      getData={
        async () => getClients({ 
          payload: {
            type: "LIVE",
            fx: {
              isInvestor: true
            }
          }
        }).then((res) => (res?.result?.docs || []))
      }
      mapper={(client)=> (
        {
          label: `${client.firstName} ${client.lastName}`,
          value: client
        }
      )}
      value={investor}
    />
  );
}
