import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import Select from "react-select";
import config from "config";
import CustomSelect from "components/Common/CustomSelect";

export default function AccountTypeDropDown({ setAccountType }) {

  const [options, setOptions] = React.useState([
    {
      value: "GOLD",
      label: "GOLD"
    },
  ]);

  const { t } = useTranslation();

  const isCrypToEnabled = config.enableCrypto;
  const isFxEnabled = config.enableFX;
  const isMMEnabled = config.enableMM;
  const isGoldEnabled = config.enableGold;

  // useEffect(() => {
  //   // One loop to rule them all
  //   const optionRef = [...options];
  //   for (let i = 0; i < optionRef.length; i++) {
  //     if (optionRef[i].value === "CRYPTO" && !isCrypToEnabled) {
  //       optionRef.splice(i, 1);
  //     }
  //     if (optionRef[i].value === "FOREX" && !isFxEnabled) {
  //       optionRef.splice(i, 1);
  //     }
  //     if (optionRef[i].value === "MM" && !isMMEnabled) {
  //       optionRef.splice(i, 1);
  //     }
  //     if (optionRef[i].value === "GOLD" && !isGoldEnabled) {
  //       optionRef.splice(i, 1);
  //     }
  //   }
  //   setOptions(optionRef);
  // }, []);

  const handleChange = (e) => {
    setAccountType(e.value);
  };
  
  return (
    <React.Fragment>
      <div
        style={{
          minWidth: "200px",
        }}
      >
        <label htmlFor="choices-single-default" className="form-label font-size-14">{t("Account Type")}</label>
        <CustomSelect
          classNamePrefix="select2-selection"
          placeholder="Select an Account Type"
          options={options}
          onChange={handleChange}
          
        />
      </div>
    </React.Fragment>
  );
}
