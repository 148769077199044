import {
  takeEvery, fork, put, all, call 
} from "redux-saga/effects";

//Account Redux states


function* accountSaga() {
  // yield all([fork()]);
}

export default accountSaga;
