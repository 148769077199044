import { combineReducers } from "redux";

import products from "./products/reducer";
import transactions from "./transactions/reducer";
import requests from "./requests/reducer";
import transfers from "./transfer/reducer";
import reports from "./report/reducer";
import deliveryFees from "./deliveryFees/reducer";

const goldReducer = combineReducers({
  products,
  transactions,
  requests,
  transfers,
  deliveryFees,
  reports
});

export default goldReducer;