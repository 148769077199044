import {
  ADD_DELIVERY_FEES_ERROR,
  ADD_DELIVERY_FEES_START,
  ADD_DELIVERY_FEES_SUCCESS,
  EDIT_DELIVERY_FEES_ERROR,
  EDIT_DELIVERY_FEES_START,
  EDIT_DELIVERY_FEES_SUCCESS,
  FETCH_DELIVERY_FEES_ERROR,
  FETCH_DELIVERY_FEES_START,
  FETCH_DELIVERY_FEES_SUCCESS
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  records: [],
  clearingCounter: 0,
  pagination: {},
  formLoading: false,
};
const deliveryFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_FEES_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_DELIVERY_FEES_SUCCESS:
      state = {
        ...state,
        loading: false,
        records: action.payload.docs,
        pagination: {
          ...action.payload,
        },
      };
      delete state.pagination.docs;
      break;
    case FETCH_DELIVERY_FEES_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case ADD_DELIVERY_FEES_START:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case ADD_DELIVERY_FEES_SUCCESS:
      state = {
        ...state,
        formLoading: false,
      };
      break;
    case ADD_DELIVERY_FEES_ERROR:
      state = {
        ...state,
        formLoading: false,
        error: action.payload,
      };
      break;
    case EDIT_DELIVERY_FEES_START:
      state = {
        ...state,
        formLoading: true,
      };
      break;
    case EDIT_DELIVERY_FEES_SUCCESS:
      state = {
        ...state,
        formLoading: false,
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case EDIT_DELIVERY_FEES_ERROR:
      state = {
        ...state,
        formLoading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default deliveryFeesReducer;
