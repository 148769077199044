import React, { useState, useEffect } from "react";
import { addProduct } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";

import SharedModal from "./SharedModal";
import { AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { fetchAssetsStart } from "store/assests/actions";
import TableLoader from "components/Common/Loader";

export default function ProductAddModal({ clearingCounter }) {
  const { t } = useTranslation();

  const { loading, assets } = useSelector((state) => state.assetReducer);
  
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const dispatch = useDispatch();

  useEffect(()=>{
    loadProductTypes(1, 100);
  }, []);
  
  const loadProductTypes = ( page, limit) => {
    dispatch(fetchAssetsStart({
      limit,
      page,
    })) ;
  };

  const handleAddProduct = (e, values = {}) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", file);
    formData.append("title", values.title);
    formData.append("price", values.price);
    formData.append("sellPrice", values.sellPrice);
    formData.append("currency", values.currency);
    formData.append("assetId", values.assetId);
    formData.append("order", values.order);
    dispatch(addProduct(formData));
  };

  useEffect(() => {
    if (isOpen) {
      toggle();
    }
  }, [clearingCounter]);

  const validateFile = (value, ctx, input, cb)=> {
    const extensions = ["png", "jpg", "jpeg"];
    const extension = value?.split(".")?.pop()?.toLowerCase();
    if (extensions.includes(extension) || !value){
      if (!value || file.size <= 2097152){
        cb(true);
      } else cb("2mb maximum size");
    } else cb("Only images can be uploaded");    
  };

  const fields = [
    <>
      <div className="mb-3">
        <AvField
          name="title"
          label="Title"
          placeholder="Enter Title"
          type="text"
          errorMessage="Enter Title"
          validate={{ required: { value: true } }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="price"
          label="Price"
          placeholder="Enter Price"
          type="number"
          errorMessage="Enter Price"
          validate={{ 
            required: { value: true },
            min: {
              value: 1,
              errorMessage: "Price must be greater than 0" 
            }
          }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="sellPrice"
          label="Sell Price"
          placeholder="Enter Price"
          type="number"
          errorMessage="Enter Price"
          validate={{ 
            required: { value: true },
            min: {
              value: 1,
              errorMessage: "Price must be greater than 0" 
            }
          }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="order"
          label="Order"
          placeholder="Enter Order"
          type="number"
          errorMessage="Enter Order"
          validate={{ 
            required: { value: true },
            min: {
              value: 1,
              errorMessage: "Order must be greater than 0" 
            }
          }}
        />
      </div>
    </>,
    <>
      {
        loading ? <div><TableLoader/> </div> :
          <div className="mb-3">
            <AvFieldSelect
              name="assetId"
              label="Type"
              placeholder="Select Type"
              options={(assets)?.filter(asset => asset?.isInventory)?.map((key) => ({
                value: key?._id,
                label: key?.symbol,
              }))}
              errorMessage="Select Type"
              validate={{ required: { value: true } }}
            />
          </div>
      }
    </>,
    <>
      <div className="mb-3">
        <AvFieldSelect
          name="currency"
          label="Currency"
          placeholder="Select Base Currency"
          options={(assets)?.filter(asset => !asset?.isInventory)?.map((key) => ({
            value: key?.symbol,
            label: key?.symbol,
          }))}
          errorMessage="Select Base Currency"
          validate={{ required: { value: true } }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="image"
          type="file"
          errorMessage={t("Please upload an image for the product")}
          validate = {{
            required :{ value:true },
            custom:validateFile 
          }}
          onChange = {(e)=>setFile(e.target.files[0])}
        />
      </div>
    </>,
  ];

  return (
    <SharedModal
      showButton={true}
      isOpen={isOpen}
      toggle={toggle}
      title="Add New Product"
      fields={fields}
      submitHandler={handleAddProduct}
      clearingCounter={0}
      buttonTitle={"Add Product"}
    />
  );
}
