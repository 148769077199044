import React from "react";
import "./skeleton.css";

export default function Skeleton() {
  return (
    <div>	
      <span className="skeleton-loader-background"></span>
    </div>
  );
}
