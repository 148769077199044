import React from "react";
import { Td, Tr } from "react-super-responsive-table";
import Skeleton from "./Skeleton";

export default function SkeletonTableLoader({
  rows = 1,
  columns = 1,
}) {
  return [...Array(rows)].map((_, i) => (
    <Tr key={i}>
      {[...Array(columns)].map((_, j) => (
        <Td key={j}>
          <Skeleton />
        </Td>
      ))}
    </Tr>
  ));
}
