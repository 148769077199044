import { editProduct, editProductImage } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { CURRENCIES } from "constants/products";
import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import SharedModal from "./SharedModal";
import { AvField } from "availity-reactstrap-validation";
import { fetchAssetsStart } from "store/assests/actions";
import TableLoader from "components/Common/Loader";
import { useTranslation } from "react-i18next";
import { imagesUrl } from "content";

export default function ProductEditModal(props) {
  const { loading, assets } = useSelector((state) => state.assetReducer);
  const { t } = useTranslation();
  const { isOpen, toggle, details } = props;
  const [file, setFile] = useState({});
  const dispatch = useDispatch();
  const handleEdit = (e, values = {}) => {
    const { title, price, currency, assetId, sellPrice } = values;
    if (!file?.size) {
      const data = {
        values: { 
          title,
          price,
          currency,
          assetId,
          sellPrice: sellPrice || 0,
          order: values?.order || 0,
        },
        id: details._id,
      };
      dispatch(editProduct(data));
    } else {
      const formData = new FormData();
      formData.append("id", details._id);
      formData.append("image", file);
      formData.append("title", title);
      formData.append("price", price);
      formData.append("sellPrice", values?.sellPrice || 0);
      formData.append("order", values?.order || 0);
      formData.append("currency", currency);
      formData.append("assetId", assetId);
      dispatch(editProductImage(formData));
    }
  };

  useEffect(()=>{
    loadProductTypes(1, 100);
  }, []);
  
  const loadProductTypes = ( page, limit) => {
    dispatch(fetchAssetsStart({
      limit,
      page,
    })) ;
  };

  const validateFile = (value, ctx, input, cb)=> {
    const extensions = ["png", "jpg", "jpeg"];
    const extension = value?.split(".")?.pop()?.toLowerCase();
    if (extensions.includes(extension) || !value){
      if (!value || file.size <= 2097152){
        cb(true);
      } else cb("2mb maximum size");
    } else cb("Only images can be uploaded");    
  };
  const fields = [
    <>
      <div className="mb-3">
        <AvField
          name="title"
          label="Title"
          placeholder="Enter Title"
          type="text"
          value={details.title}
          errorMessage="Enter Title"
          validate={{ required: { value: true } }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="price"
          label="Price"
          placeholder="Enter Price"
          type="number"
          errorMessage="Enter Price"
          value={details.price}
          validate={{ 
            required: { value: true },
            min: {
              value: 1,
              errorMessage: "Price must be greater than 0" 
            }
          }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="sellPrice"
          label="Sell Price"
          placeholder="Enter Price"
          type="number"
          errorMessage="Enter Price"
          value={details?.sellPrice || 0}
          validate={{ 
            required: { value: true },
            min: {
              value: 1,
              errorMessage: "Price must be greater than 0" 
            }
          }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="order"
          label="Order"
          placeholder="Enter Order"
          type="number"
          errorMessage="Enter Price"
          value={details?.order || 0}
          validate={{ 
            required: { value: true },
            min: {
              value: 1,
              errorMessage: "Order must be greater than 0" 
            }
          }}
        />
      </div>
    </>,
    <>
      {
        loading ? <div><TableLoader/> </div> :
          <div className="mb-3">
            <AvFieldSelect
              name="assetId"
              label="Type"
              placeholder="Select Type"
              value={details.assetId?._id}
              options={(assets)?.filter(asset => asset?.isInventory)?.map((key) => ({
                value: key?._id,
                label: key?.symbol,
              }))}
              errorMessage="Select Type"
              validate={{ required: { value: true } }}
            />
          </div>
      }
    </>,
    <>
      <div className="mb-3">
        <AvFieldSelect
          name="currency"
          label="Currency"
          placeholder="Select Currency"
          value={details.currency}
          options={(assets)?.filter(asset => !asset?.isInventory)?.map((key) => ({
            value: key?.symbol,
            label: key?.symbol,
          }))}
          errorMessage="Select Currency"
          validate={{ required: { value: true } }}
        />
      </div>
    </>,
    <>
      <div className="d-flex justify-content-center my-5">
        <img
          src={`${imagesUrl}${details.imageUrl}`}
          alt="product"
          height={200}
          width={200}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="image"
          type="file"
          errorMessage={t("Please upload an image for the product")}
          validate = {{
            custom: validateFile 
          }}
          onChange = {(e)=>setFile(e.target.files[0])}
        />
      </div>
    </>
  ];

  return (
    <SharedModal
      showButton={false}
      isOpen={isOpen}
      toggle={toggle}
      title="Edit Product"
      fields={fields}
      submitHandler={handleEdit}
      clearingCounter={0}
      buttonTitle={"Edit"}
    />
  );
}
