import React from "react";
// eslint-disable-next-line object-curly-newline
import { Card, CardBody, Row } from "reactstrap";
import AccountDetailsCol from "./AccountDetailsCol";
import { profiles } from "common/mam-pamm";

const AccountDetails = ({ profile }) => {

  const allocationType = profiles.find((item) => item.value === profile?.profileType)?.label ?? "-";

  return (
    <Card className="h-100">
      <CardBody className="d-flex flex-column">
        <Row className="text-center justify-content-between">
          <AccountDetailsCol
            label={"Allocation Type"}
            value={allocationType}
          />
          <AccountDetailsCol label={"Trading Account"} value={profile?.masterTradingAccountId?.login} />
        </Row>
        <Row className="text-center justify-content-between">
          <AccountDetailsCol label={"Fee Account"} value={profile?.feeTradingAccountId?.login} />
          <AccountDetailsCol label={"Linked Investors"} value={profile?.slaves?.length ?? 0} />
        </Row>
      </CardBody>
    </Card>
  );
};

export default AccountDetails;
