export const FETCH_MAM_PROFILE_START = "FETCH_MAM_PROFILE_START";
export const FETCH_MAM_PROFILE_SUCCESS = "FETCH_MAM_PROFILE_SUCCESS";
export const FETCH_MAM_PROFILE_FAIL = "FETCH_MAM_PROFILE_FAIL";

export const CREATE_MAM_PROFILE_START = "CREATE_MAM_PROFILE_START";
export const CREATE_MAM_PROFILE_SUCCESS = "CREATE_MAM_PROFILE_SUCCESS";
export const CREATE_MAM_PROFILE_FAIL = "CREATE_MAM_PROFILE_FAIL";

export const LINK_INVESTOR_TO_PROFILE_START = "LINK_INVESTOR_TO_PROFILE_START";
export const LINK_INVESTOR_TO_PROFILE_SUCCESS = "LINK_INVESTOR_TO_PROFILE_SUCCESS";
export const LINK_INVESTOR_TO_PROFILE_FAIL = "LINK_INVESTOR_TO_PROFILE_FAIL";

export const UNLINK_INVESTOR_FROM_PROFILE_START = "UNLINK_INVESTOR_FROM_PROFILE_START";
export const UNLINK_INVESTOR_FROM_PROFILE_SUCCESS = "UNLINK_INVESTOR_FROM_PROFILE_SUCCESS";
export const UNLINK_INVESTOR_FROM_PROFILE_FAIL = "UNLINK_INVESTOR_FROM_PROFILE_FAIL";

export const LINK_MASTER_TO_INVESTOR_START = "LINK_MASTER_TO_INVESTOR_START";
export const LINK_MASTER_TO_INVESTOR_SUCCESS = "LINK_MASTER_TO_INVESTOR_SUCCESS";
export const LINK_MASTER_TO_INVESTOR_FAIL = "LINK_MASTER_TO_INVESTOR_FAIL";

export const UNLINK_MASTER_FROM_INVESTOR_START = "UNLINK_MASTER_FROM_INVESTOR_START";
export const UNLINK_MASTER_FROM_INVESTOR_SUCCESS = "UNLINK_MASTER_FROM_INVESTOR_SUCCESS";
export const UNLINK_MASTER_FROM_INVESTOR_FAIL = "UNLINK_MASTER_FROM_INVESTOR_FAIL";
