
export const FETCH_STRATEGY_START = "FETCH_STRATEGY_START";
export const FETCH_STRATEGY_SUCCESS = "FETCH_STRATEGY_SUCCESS";
export const FETCH_STRATEGY_ERROR = "FETCH_STRATEGY_ERROR";

export const ADD_STRATEGY_START = "ADD_STRATEGY_START";
export const ADD_STRATEGY_SUCCESS = "ADD_STRATEGY_SUCCESS";
export const ADD_STRATEGY_ERROR = "ADD_STRATEGY_ERROR";
export const ADD_STRATEGY_CLEAR = "ADD_STRATEGY_CLEAR";


export const EDIT_STRATEGY_START = "EDIT_STRATEGY_START";
export const EDIT_STRATEGY_DONE = "EDIT_STRATEGY_DONE";
export const EDIT_STRATEGY_CLEAR = "EDIT_STRATEGY_CLEAR";

export const DELETE_STRATEGY_START = "DELETE_STRATEGY_START";
export const DELETE_STRATEGY_DONE =  "DELETE_STRATEGY_DONE";

export const CHANGE_STATUS_STRATEGY_START = "CHANGE_STATUS_STRATEGY_START";
export const CHANGE_STATUS_STRATEGY_END = "CHANGE_STATUS_STRATEGY_END";
