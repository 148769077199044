export const FETCH_LEADS_START = "FETCH_LEADS_START";
export const FETCH_LEADS_SUCCESS = "FETCH_LEADS_SUCCESS";
export const FETCH_LEADS_FAILED = "FETCH_LEADS_FAILED";
export const API_ERROR = "API_ERROR";
export const ADD_NEW_LEAD = "ADD_NEW_LEAD";
export const ADD_NEW_LEAD_SUCCESS = "ADD_NEW_LEAD_SUCCESS";
export const ADD_MODAL_CLEAR = "ADD_MODAL_CLEAR";
export const ADD_NEW_LEAD_EXCEL = "ADD_NEW_LEAD_EXCEL";
export const ADD_NEW_LEAD_EXCEL_SUCCESS = "ADD_NEW_LEAD_EXCEL_SUCCESS";
export const ADD_NEW_LEAD_EXCEL_FAILED = "ADD_NEW_LEAD_EXCEL_FAILED";

export const UPDATE_CALL_STATUS = "UPDATE_CALL_STATUS";
export const UPDATE_CALL_STATUS_SUCCESS = "UPDATE_CALL_STATUS_SUCCESS";
export const UPDATE_CALL_STATUS_FAILED = "UPDATE_CALL_STATUS_FAILED";