
export const SAVE_EMAIL_CONFIGURATION = "SAVE_EMAIL_CONFIGURATION";
export const SAVE_EMAIL_CONFIGURATION_SUCCESS = "SAVE_EMAIL_CONFIGURATION_SUCCESS";
export const SAVE_EMAIL_CONFIGURATION_FAIL = "SAVE_EMAIL_CONFIGURATION_FAIL";

export const CHANGE_ACTIVE_EMAIL_CONFIGURATION = "CHANGE_ACTIVE_EMAIL_CONFIGURATION";
export const CHANGE_ACTIVE_EMAIL_CONFIGURATION_SUCCESS = "CHANGE_ACTIVE_EMAIL_CONFIGURATION_SUCCESS";
export const CHANGE_ACTIVE_EMAIL_CONFIGURATION_FAIL = "CHANGE_ACTIVE_EMAIL_CONFIGURATION_FAIL";

export const TEST_EMAIL_CONFIGURATION = "TEST_EMAIL_CONFIGURATION";
export const TEST_EMAIL_CONFIGURATION_SUCCESS = "TEST_EMAIL_CONFIGURATION_SUCCESS";
export const TEST_EMAIL_CONFIGURATION_FAIL = "TEST_EMAIL_CONFIGURATION_FAIL";

export const FETCH_EMAIL_CONFIGURATION = "FETCH_EMAIL_CONFIGURATION";
export const FETCH_EMAIL_CONFIGURATION_SUCCESS = "FETCH_EMAIL_CONFIGURATION_SUCCESS";
export const FETCH_EMAIL_CONFIGURATION_FAIL = "FETCH_EMAIL_CONFIGURATION_FAIL";

export const FETCH_NOTIFICATION_GROUPS = "FETCH_NOTIFICATION_GROUPS";
export const FETCH_NOTIFICATION_GROUPS_SUCCESS = "FETCH_NOTIFICATION_GROUPS_SUCCESS";
export const FETCH_NOTIFICATION_GROUPS_FAIL = "FETCH_NOTIFICATION_GROUPS_FAIL";

export const UPDATE_NOTIFICATION_GROUPS = "UPDATE_NOTIFICATION_GROUPS";
export const UPDATE_NOTIFICATION_GROUPS_SUCCESS = "UPDATE_NOTIFICATION_GROUPS_SUCCESS";
export const UPDATE_NOTIFICATION_GROUPS_FAIL = "UPDATE_NOTIFICATION_GROUPS_FAIL";