

import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getStrategy = async({ payload }) => {
  const data = await axiosHelper.get(`/strategy?${qs.stringify(payload)}`);
  return data.result;
};

export const addStrategy = async({ payload }) => {
  const data = await axiosHelper.post("/strategy", payload);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const editStrategy = async({ payload }) => {
  const { id, values } = payload;
  const data = await axiosHelper.patch(`/strategy/${id}`, values);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const deleteStrategy = async({ payload }) => {
  const data = await axiosHelper.del(`/strategy/${payload}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};

export const changeStatusStrategy = async({ payload }) => {
  const data = await axiosHelper.post(`/strategy/${payload.id}/${payload.status}`);
  if (data.isError) {
    throw new Error(data.message);
  } 
  return data;
};