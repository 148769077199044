// fetch converts
export const FETCH_CONVERTS_REQUESTED = "FETCH_CONVERTS_REQUESTED";
export const FETCH_CONVERTS_SUCCESS = "FETCH_CONVERTS_SUCCESS";
export const FETCH_CONVERTS_FAIL = "FETCH_CONVERTS_FAIL";

// add convert
export const ADD_CONVERT_REQUESTED = "ADD_CONVERT_REQUESTED";
export const ADD_CONVERT_SUCCESS = "ADD_CONVERT_SUCCESS";
export const ADD_CONVERT_FAIL = "ADD_CONVERT_FAIL";
export const ADD_CONVERT_CLEAR = "ADD_CONVERT_CLEAR";
export const ADD_CONVERT_ERROR_CLEAR = "ADD_CONVERT_ERROR_CLEAR";