import {
  FETCH_MM_WITHDRAW_REQUESTED,
  FETCH_MM_WITHDRAW_SUCCESS,
  FETCH_MM_WITHDRAW_FAIL,

  ADD_MM_WITHDRAW_REQUESTED,
  ADD_MM_WITHDRAW_SUCCESS,
  ADD_MM_WITHDRAW_FAIL,
  ADD_MM_WITHDRAW_CLEAR,
  ADD_MM_WITHDRAW_ERROR_CLEAR,
  APPROVE_MM_WITHDRAW,
  APPROVE_MM_WITHDRAW_SUCCESS,
  REJECT_MM_WITHDRAW_SUCCESS
} from "./actionTypes";

const initalState = {
  mmWithdrawals: [],
  fetchLoading: false,
  addLoading: false,
  error: "",
  modalClear: false,
  mmWithdrawResponseMessage: "",
  addMMWithdrawClearingCounter: 0
};

const mmWithdrawReducer = (state = initalState, action) => {
  switch (action.type){
    // fetch mm withdraws
    case FETCH_MM_WITHDRAW_REQUESTED:
      state = {
        ...state,
        fetchLoading: true
      };
      break;
    case FETCH_MM_WITHDRAW_SUCCESS:
      state = {
        ...state,
        mmWithdrawals: [...action.payload.result.docs],
        totalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        fetchLoading: false,  
      };
      break;
    case FETCH_MM_WITHDRAW_FAIL:
      state = {
        ...state,
        fetchLoading: false,
        mmWithdrawError: action.payload
      };
      break;

    // add mm Withdraw
    case ADD_MM_WITHDRAW_REQUESTED:
      state = {
        ...state,
        addLoading: true,
        addMMWithdrawSuccess: false,
        addMMWithdrawFail: false,
        addMMWithdrawFailDetails: ""
      };
      break;
    case ADD_MM_WITHDRAW_SUCCESS:
      state = {
        ...state,
        newMMWithdraw: action.payload.result,
        addMMWithdrawSuccess: true,
        addMMWithdrawFail: false
      };
      break;
    case ADD_MM_WITHDRAW_FAIL:
      state = {
        ...state,
        addMMWithdrawSuccess: false,
        addMMWithdrawFail: true,
        addLoading: false,
        addMMWithdrawFailDetails: action.payload.error
      };
      break;
    case ADD_MM_WITHDRAW_CLEAR:
      state = {
        ...state,
        addLoading: false,
        addMMWithdrawClearingCounter: state.addMMWithdrawClearingCounter + 1,
        addMMWithdrawFail: false,
        addMMWithdrawSuccess: false,
        modalClear: true,
        addMMWithdrawFailDetails: ""
      };
      break;
    case ADD_MM_WITHDRAW_ERROR_CLEAR:
      state = {
        ...state,
        addMMWithdrawFail: false,
        addMMWithdrawSuccess: false,
        addMMWithdrawFailDetails: null
      };
      break;
    case APPROVE_MM_WITHDRAW:
      return {
        ...state,
      };
    case APPROVE_MM_WITHDRAW_SUCCESS:
      return {
        ...state,
        mmWithdrawals:state.mmWithdrawals.map(d => {
          if (d._id === action.payload){
            d.status = "APPROVED";
          }
          return d;
        }),
      };
    case REJECT_MM_WITHDRAW_SUCCESS:
      return {
        ...state,
        mmWithdrawals:state.mmWithdrawals.map(d => {
          if (d._id === action.payload){
            d.status = "REJECTED";
          }
          return d;
        }),
      };
    default:
      state = { ...state };
  }
  return state;
};

export default mmWithdrawReducer;