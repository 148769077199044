export const FETCH_EMAIL_CAMPAIGNS_REQUESTED = "FETCH_EMAIL_CAMPAIGNS_REQUESTED";
export const FETCH_EMAIL_CAMPAIGNS_SUCCESS = "FETCH_EMAIL_CAMPAIGNS_SUCCESS";
export const FETCH_EMAIL_CAMPAIGNS_FAIL = "FETCH_EMAIL_CAMPAIGNS_FAIL";

export const ADD_EMAIL_CAMPAIGN_REQUESTED = "ADD_EMAIL_CAMPAIGN_REQUESTED";
export const ADD_EMAIL_CAMPAIGN_SUCCESS = "ADD_EMAIL_CAMPAIGN_SUCCESS";
export const ADD_EMAIL_CAMPAIGN_FAIL = "ADD_EMAIL_CAMPAIGN_FAIL";
export const ADD_EMAIL_CAMPAIGN_CLEAR = "ADD_EMAIL_CAMPAIGN_CLEAR";

export const DELETE_EMAIL_CAMPAIGN_REQUESTED = "DELETE_EMAIL_CAMPAIGN_REQUESTED";
export const DELETE_EMAIL_CAMPAIGN_SUCCESS = "DELETE_EMAIL_CAMPAIGN_SUCCESS";
export const DELETE_EMAIL_CAMPAIGN_FAIL = "DELETE_EMAIL_CAMPAIGN_FAIL";

export const EDIT_EMAIL_CAMPAIGN_REQUESTED = "EDIT_EMAIL_CAMPAIGN_REQUESTED";
export const EDIT_EMAIL_CAMPAIGN_SUCCESS = "EDIT_EMAIL_CAMPAIGN_SUCCESS";
export const EDIT_EMAIL_CAMPAIGN_FAIL = "EDIT_EMAIL_CAMPAIGN_FAIL";
export const EDIT_EMAIL_CAMPAIGN_CLEAR = "EDIT_EMAIL_CAMPAIGN_CLEAR";

export const GET_CLIENT_GROUPS_REQUESTED = "GET_CLIENT_GROUPS_REQUESTED";
export const GET_CLIENT_GROUPS_SUCCESS = "GET_CLIENT_GROUPS_SUCCESS";
