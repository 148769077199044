import * as axiosHelper from "./api_helper";
import qs from "qs";

export const fetchDemoFromAPI = async ({ payload }) => {
  const result = await axiosHelper.get(`/demo?${qs.stringify(payload)}`);
  return result;
};

export const addDemoAPI = async (values) => {
  const data = await axiosHelper.post("/demo", { ...values });
  if (data.isSuccess === false) {
    throw new Error(data.message);
  }
  return data;
};