import {
  FETCH_TRANSACTIONS_ERROR,
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS
} from "./actionTypes";

const initialState = {
  transactions: [],
  transactionPagination: {},
  loading: false,
  error: "",
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_TRANSACTIONS_SUCCESS:
      state = {
        ...state,
        transactions: [...action.payload.docs],
        transactionPagination: {
          docs: undefined,
          ...action.payload,
        },
        loading: false,
      };
      break;
    case FETCH_TRANSACTIONS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      break;
  }
  return state;
};

export default transactionsReducer;