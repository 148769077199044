import {
  FETCH_PRODUCT_REQUESTS_ERROR,
  FETCH_PRODUCT_REQUESTS_START,
  FETCH_PRODUCT_REQUESTS_SUCCESS,
  UPDATE_PRODUCT_REQUEST_ERROR,
  UPDATE_PRODUCT_REQUEST_START,
  UPDATE_PRODUCT_REQUEST_SUCCESS
} from "./actionTypes";


export const fetchProductRequests = (params) => {
  return {
    type: FETCH_PRODUCT_REQUESTS_START,
    payload: params,
  };
};

export const fetchProductRequestsSuccess = (data) => {
  return {
    type: FETCH_PRODUCT_REQUESTS_SUCCESS,
    payload: data,
  };
};

export const fetchProductRequestsError = (error) => {
  return {
    type: FETCH_PRODUCT_REQUESTS_ERROR,
    payload: error,
  };
};

export const updateProductRequest = (params) => {
  return {
    type: UPDATE_PRODUCT_REQUEST_START,
    payload: params,
  };
};

export const updateProductRequestSuccess = (data) => {
  return {
    type: UPDATE_PRODUCT_REQUEST_SUCCESS,
    payload: data,
  };
};

export const updateProductRequestError = (error) => {
  return {
    type: UPDATE_PRODUCT_REQUEST_ERROR,
    payload: error,
  };
};