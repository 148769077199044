import React from "react";
import { useSelector, connect } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// i18n
import { withTranslation } from "react-i18next";
import ClientStages from "./Stages";
import IbStages from "./IbStages";
import Activities from "./Activities";
import { useParams } from "react-router-dom";

function Logs(props) {
  const { clientId } = useParams();
  const userData = useSelector((state) => state.Profile);
  const permissions = userData?.roleId?.permissions;
  const { clientLogs } = permissions;
  return (
    <>
      {clientLogs.get ? (
        <>
          {props.fx?.isClient && <ClientStages clientId={clientId} />}
          {props.fx?.isIb && <IbStages clientId={clientId} />}
          <Activities clientId={clientId} />
        </>
      ) : (
        <div className="text-center">
          <h2>You Do Not Have The Required Permission</h2>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  fx: state.clientReducer.clientDetails.fx
});

export default connect(mapStateToProps, null)(withTranslation()(Logs));
