import { updateOfficialSp } from "apis/client";
import { AvForm } from "availity-reactstrap-validation";
import TableLoader from "components/Common/Loader";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import { fetchClientDetails } from "store/client/actions";

export default function OfficialSp(props) {
  const {
    isLead,
    clientId,
    kyc = true,
    isOfficialSp = false,
  } = props;
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const toggleModal = () => setShow(!show);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    setLoading(true);
    updateOfficialSp({
      clientId,
      isOfficialSp: isOfficialSp ? false : true
    }).then((res) => {
      if ( res?.isSuccess) {
        dispatch(showSuccessNotification("Successfully updated"));
        dispatch(fetchClientDetails(clientId));
        return toggleModal();
      }
      throw new Error(res?.message);
    }).catch((err) => {
      console.log(err);
      dispatch(showErrorNotification(err.message || "Something went wrong"));
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t(isOfficialSp ? "Remove Official SP" : "Add Official SP")}
      </button>
      <Modal isOpen={show} toggle={toggleModal} centered={true}>
        <AvForm
          onValidSubmit={(e, v) => handleConfirm(e, v)}
        >
          <ModalHeader toggle={toggleModal}>
            {t("Official SP")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <h5>{isOfficialSp ? t("Are you sure you want remove this SP from listing") : t("Are you sure you want to add this SP to listing")} ? </h5>
            </Row>
            <Row>
              <Col>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal} color="secondary">
              {t("No")}
            </Button>
            {loading ? <TableLoader /> : (
              <Button type="submit" color="primary" disabled={!kyc}>
                {t("Yes")}
              </Button>
            )}
          </ModalFooter>
        </AvForm>
      </Modal>
    </React.Fragment >
  );
}
