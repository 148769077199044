// fetch MM deposits
export const FETCH_MM_DEPOSITS_REQUESTED = "FETCH_MM_DEPOSITS_REQUESTED";
export const FETCH_MM_DEPOSITS_SUCCESS = "FETCH_MM_DEPOSITS_SUCCESS";
export const FETCH_MM_DEPOSITS_FAIL = "FETCH_MM_DEPOSITS_FAIL";

export const ADD_MM_DEPOSIT_REQUESTED = "ADD_MM_DEPOSIT_REQUESTED";
export const ADD_MM_DEPOSIT_SUCCESS = "ADD_MM_DEPOSIT_SUCCESS";
export const ADD_MM_DEPOSIT_FAIL = "ADD_MM_DEPOSIT_FAIL";
export const ADD_MM_DEPOSIT_CLEAR = "ADD_MM_DEPOSIT_CLEAR";
export const ADD_MM_DEPOSIT_ERROR_CLEAR = "ADD_MM_DEPOSIT_ERROR_CLEAR";
export const APPROVE_MM_DEPOSIT = "APPROVE_MM_DEPOSIT";
export const APPROVE_MM_DEPOSIT_SUCCESS = "APPROVE_MM_DEPOSIT_SUCCESS";
export const REJECT_MM_DEPOSIT = "REJECT_MM_DEPOSIT";
export const REJECT_MM_DEPOSIT_SUCCESS = "REJECT_MM_DEPOSIT_SUCCESS";

export const FETCH_CLIENT_SUBSCRIPTIONS_REQUESTED = "FETCH_CLIENT_SUBSCRIPTIONS_REQUESTED";
export const FETCH_CLIENT_SUBSCRIPTIONS_SUCCESS = "FETCH_CLIENT_SUBSCRIPTIONS_SUCCESS";