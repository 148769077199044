import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApproveDetailsModal from "pages/Requests/Master/ApproveDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { convertToSP, fetchClientDetails } from "store/client/actions";

function ConvertToSP({ isLead, kyc = true, id }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const toggleModal = () => {
    setShow(!show);
  };
  const {
    clearingCounter,
  } = useSelector(state => state.clientReducer.convertToSp);

  useEffect(() => {
    if (show) {
      dispatch(fetchClientDetails(id));
      setShow(false);
    }
  }, [clearingCounter]);

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Convert To Master")}
      </button>
      { show && <>
        <ApproveDetailsModal isOpen={show} toggleModal={() => {
          dispatch(fetchClientDetails(id));
          toggleModal();
        }} customerId={id} isDisabled={!kyc} handler={(values) =>{
          dispatch(convertToSP({
            id,
            ...values
          }));
        }} title={t("Convert to Master")}
        />
      </>}
    </React.Fragment >
  );
}

export default (ConvertToSP);