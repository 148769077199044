import React from "react";
import Report from "./Report";

export default function ProductReports() {
  return (
    <>
      <Report />
    </>
  );
}
