import React, { useState } from "react";
import ProductsFilter from "./ProductsFilter";
import { Table } from "reactstrap";
import {
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableLoader from "components/Common/TableLoader";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

export default function Report() {

  const [columns, setColumns] = useState([]);

  const getColumns = (type) => {
    switch (type) {
      case "all":
      case "":
        return [
          {
            dataField: "createdAt",
            text: "Created At",
            formatter: (row) => <>
              {moment(row.createdAt).format("DD/MM/YYYY")}
            </>
          },
          {
            dataField: "customer",
            text: "Customer",
            formatter: (row) => <Link to={`/clients/${row?.customerId?._id}/profile`}>
              {`${row?.customerId?.firstName} ${row?.customerId?.lastName}`}
            </Link>
          },
          {
            dataField: "type",
            text: "Type",
          },
          {
            dataField: "quantity",
            text: "Quantity",
          },
          {
            dataField: "amount",
            text: "Amount",
            formatter: (row) => <>
              {row?.amount?.$numberDecimal != 0 ? parseFloat(row?.amount?.$numberDecimal)?.toFixed(2) : "Not Available"}
            </>
          },
          {
            dataField: "currency",
            text: "Currency",
          },
          {
            dataField: "status",
            text: "Status",
          }
        ];
      case "delivery":
        return [
          {
            dataField: "createdAt",
            text: "Created At",
            formatter: (row) => <>
              {moment(row.createdAt).format("DD/MM/YYYY")}
            </>
          },
          {
            dataField: "Delivered",
            text: "Delivered At",
            formatter: (row) => <>
              {moment(row.updatedAt).format("DD/MM/YYYY")}
            </>
          },
          {
            dataField: "customer",
            text: "Customer",
            formatter: (row) => <Link to={`/clients/${row?.customerId?._id}/profile`}>
              {`${row?.customerId?.firstName} ${row?.customerId?.lastName}`}
            </Link>
          },
          {
            dataField: "type",
            text: "Type",
          },
          {
            dataField: "quantity",
            text: "Quantity",
          },
          {
            dataField: "amount",
            text: "Delivery Fees",
            formatter: (row) => <>
              {parseFloat(row?.deliveryDetails?.deliveryFees?.$numberDecimal  || 0)?.toFixed(2)}
            </>
          },
          {
            dataField: "amount",
            text: "Amount",
            formatter: (row) => <>
              {parseFloat(row?.amount?.$numberDecimal - (row?.deliveryDetails?.deliveryFees?.$numberDecimal || 0))?.toFixed(2)}
            </>
          },
          {
            dataField: "currency",
            text: "Currency",
          },
          {
            dataField: "status",
            text: "Status",
          }
        ];
      case "sell-back":
      case "storage":
        return [
          {
            dataField: "createdAt",
            text: "Created At",
            formatter: (row) => <>
              {moment(row.createdAt).format("DD/MM/YYYY")}
            </>
          },
          {
            dataField: "approvedAt",
            text: "Approved At",
            formatter: (row) => <>
              {moment(row.updatedAt).format("DD/MM/YYYY")}
            </>
          },
          {
            dataField: "customer",
            text: "Customer",
            formatter: (row) => <Link to={`/clients/${row?.customerId?._id}/profile`}>
              {`${row?.customerId?.firstName} ${row?.customerId?.lastName}`}
            </Link>
          },
          {
            dataField: "type",
            text: "Type",
          },
          {
            dataField: "quantity",
            text: "Quantity",
          },
          {
            dataField: "amount",
            text: "Amount",
            formatter: (row) => <>
              {row?.amount?.$numberDecimal != 0 ? parseFloat(row?.amount?.$numberDecimal)?.toFixed(2) : "Not Available"}
            </>
          },
          {
            dataField: "currency",
            text: "Currency",
          },
          {
            dataField: "status",
            text: "Status",
          }
        ];
      default : 
        return [];
    }
  };
  const { loading, reports } = useSelector(state => state.goldReducer.reports);
  return (
    <React.Fragment>
      <ProductsFilter setReportColumns={(type)=>{
        setColumns(getColumns(type));
      }} >                    
        <Table
          id="tech-companies-1"
          className="table  table-hover "
        >
          <Thead className="text-center table-light" >
            <Tr>
              {columns?.map((column, index) =>
                <Th data-priority={index} key={index}>{column?.text}</Th>
              )}
            </Tr>
          </Thead>
          <Tbody className="text-center" style={{ fontSize: "13px" }}>
            {loading && <TableLoader colSpan={4} />}
            {!loading && reports?.length === 0 && 
              <>
                <Tr>
                  <Td colSpan={"100%"} className="fw-bolder text-center" >
                    <h3 className="fw-bolder text-center">No records</h3>
                  </Td>
                </Tr>
              </>
            }
            {!loading && reports?.map((row, rowIndex) =>
              <Tr key={rowIndex}>
                {columns.map((column, index) =>
                  <Td key={`${rowIndex}-${index}`}>
                    { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                  </Td>
                )}
              </Tr>
            )}
          </Tbody>
        </Table>
      </ProductsFilter>
    </React.Fragment>
  );
}
