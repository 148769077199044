import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  useDispatch, connect
} from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addStrategy } from "store/strategy/actions";
import Loader from "components/Common/Loader";

function StrategyAdd (props) {
  const [addModal, setAddUserModal] = useState(false);
  const dispatch = useDispatch();
  const { create } = props.rolesPermissions;
  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };
  const strategyTitles = props.strategy?.map((strategy) => (strategy.name?.toLowerCase()));
  const handleAddStrategy = (e, values) => {
    dispatch(addStrategy(values));
  };
  useEffect(()=>{
    if (props.clearingCounter > 0 && addModal) {
      setAddUserModal(false);
    }
  }, [props.clearingCounter]);

  const validateStrategyTitle = (value, ctx, input, cb) => {
    if (strategyTitles?.includes(value?.toLowerCase()) || strategyTitles?.includes(value)){
      cb("This Strategy Already Exists");
    } else {
      cb(true);
    } 
  };

  return (
    <React.Fragment >
      <Link to="#"  className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> Add New Strategy</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
            Add New Strategy
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleAddStrategy(e, v);
            }}
          >
            <div className="mb-3">
              <AvField
                name="name"
                label="Strategy Name"
                placeholder="Enter Strategy Name"
                type="text"
                errorMessage="Enter Strategy Name"
                validate={
                  { 
                    required: { value: true },
                    customer: validateStrategyTitle 
                  }
                }
              />
            </div>
            <div className="mb-3">
              <AvField
                name="lockInPeriod"
                label="Duration"
                type="select"
                errorMessage="Duration Required"
                validate={
                  { 
                    required: { value: true },
                  }
                }
              >
                <option value={604800}>1 Week</option>
                <option value={2592000}>1 Month</option>
                <option value={2592000 * 3}>3 Month</option>
                <option value={2592000 * 6}>6 Month</option>
                <option value={31556952}>1 Year</option>
              </AvField>
            </div>
            <div className="mb-3">
              <AvField
                name="minDeposit"
                label="Minimum Deposit"
                placeholder="Enter Minimum Deposit"
                type="number"
                errorMessage="Minimum Deposit Required"
                validate={
                  { 
                    required: { value: true },
                  }
                }
              />
            </div>
            <div className='text-center pt-3 p-2'>
              {
                props.addLoading 
                  ?
                  <Loader />
                  :
                  <Button disabled={props.addLoading} type="submit" color="primary" className="">
                    Add
                  </Button>
              }
            </div>
          </AvForm>
          {props.addError && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.addErrorDetails}
          </UncontrolledAlert>}
          {props.addSuccess && <UncontrolledAlert color="success">
            <i className="mdi mdi-check-all me-2"></i>
            Strategy Added successfully !!!
          </UncontrolledAlert>}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  addLoading: state.strategyReducer.addLoading,
  addErrorDetails: state.strategyReducer.addErrorDetails,
  addSuccess: state.strategyReducer.addSuccess,
  addError: state.strategyReducer.addError,  
  clearingCounter: state.strategyReducer.clearingCounter,  
  rolesPermissions: state.Profile.rolesPermissions,
  strategy: state.strategyReducer.docs
});
export default connect(mapStateToProps, null)(StrategyAdd);