import {
  FETCH_STRATEGY_START,
  FETCH_STRATEGY_SUCCESS,
  FETCH_STRATEGY_ERROR,
  ADD_STRATEGY_START,
  ADD_STRATEGY_SUCCESS,
  ADD_STRATEGY_ERROR,
  ADD_STRATEGY_CLEAR,

  EDIT_STRATEGY_START,
  EDIT_STRATEGY_DONE,
  EDIT_STRATEGY_CLEAR,

  DELETE_STRATEGY_START,
  DELETE_STRATEGY_DONE,

  CHANGE_STATUS_STRATEGY_START,
  CHANGE_STATUS_STRATEGY_END
} from "./actionTypes";

export const fetchStrategy = (params = {})=>{
  return {
    type:FETCH_STRATEGY_START,
    payload: params
  };
};
export const fetchStrategySuccess = (data)=>{
  return {
    type:FETCH_STRATEGY_SUCCESS,
    payload: data
  };
};
export const fetchStrategyError = (error)=>{
  return {
    type:FETCH_STRATEGY_ERROR,
    payload:{ error }
  };
};


export const addStrategy = (params = {})=>{
  return {
    type:ADD_STRATEGY_START,
    payload: params
  };
};
export const addStrategySuccess = (data)=>{
  return {
    type:ADD_STRATEGY_SUCCESS,
    payload: data
  };
};
export const addStrategyError = (error)=>{
  return {
    type:ADD_STRATEGY_ERROR,
    payload: error && error.message || "",
  };
};
export const addStrategyClear = (data)=>{
  return {
    type:ADD_STRATEGY_CLEAR,
    payload: data
  };
};

export const editStrategy = (params = {})=>{
  return {
    type:EDIT_STRATEGY_START,
    payload: params
  };
};
export const editStrategyDone = (data)=>{
  return {
    type:EDIT_STRATEGY_DONE,
    payload: data
  };
};
export const editStrategyClear = (data)=>{
  return {
    type:EDIT_STRATEGY_CLEAR,
    payload: data
  };
};

export const deleteStrategy = (params = {})=>{
  return {
    type:DELETE_STRATEGY_START,
    payload: params
  };
};
export const deleteStrategyDone = (data)=>{
  return {
    type:DELETE_STRATEGY_DONE,
    payload: data
  };
};
export const changeStatus = (id, status, index)=>{
  return {
    type: CHANGE_STATUS_STRATEGY_START,
    payload: {
      id, 
      status,
      index,
    },
  };
};

export const changeStatusDone = (params = {})=>{
  return {
    type:CHANGE_STATUS_STRATEGY_END,
    payload: params
  };
};