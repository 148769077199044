import {
  FETCH_PRODUCT_REPORTS_ERROR,
  FETCH_PRODUCT_REPORTS_START,
  FETCH_PRODUCT_REPORTS_SUCCESS
} from "./actionTypes";

export const fetchReports = (params) => {
  return {
    type: FETCH_PRODUCT_REPORTS_START,
    payload: params,
  };
};

export const fetchReportsSuccess = (data) => {
  return {
    type: FETCH_PRODUCT_REPORTS_SUCCESS,
    payload: data,
  };
};

export const fetchReportsError = (error) => {
  return {
    type: FETCH_PRODUCT_REPORTS_ERROR,
    payload: error,
  };
};
