import { connect } from "react-redux";
import {
  Button,
  UncontrolledAlert,
  Col,
  Row,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap";
import { withTranslation } from "react-i18next";
import React, {
  useState,
  useEffect,
} from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FromToDate from "../../components/Common/Filters/FromToDate";
import { useLocation } from "react-router-dom";
import ClientDropDown from "components/Common/Filters/ClientDropDown";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";

function Filter(props) {
  const [openCanvas, setOpenCanvas] = useState(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const initValues = {
    // firstName: "",
    // lastName: "",
    // // this name is for content object
    // clientName: "",
    filterDate: {
      fromDate: query.get("fromDate") || "",
      toDate: ""
    },
    customerId: "",
    agent: ""
  };
  const { filteredValues = initValues } = props;
  const [values, setValues] = useState(filteredValues);
  const [filtered, setFilter] = useState(false);

  useEffect(() => {
    let isFiltered = JSON.stringify(values).split("").length !== JSON.stringify(initValues).split("").length;
    setFilter(isFiltered);
  }, [values]);

  const resetFilter = () => {
    setValues(initValues);
    props.filterChangeHandler(initValues);
    // setOpenCanvas(false);
  };

  const dateChangeHandler = (filterDate) => {
    setValues({
      ...values,
      filterDate: filterDate
    });
  };

  const toggleCanvas = () => {
    setOpenCanvas(!openCanvas);
  };

  const clientChangeHandler = (obj) => {
    setValues({
      ...values,
      customerId: obj.value
    });
  };

  useEffect(() => {
    if (!props.showAddSuccessMessage && openCanvas) {
      setOpenCanvas(false);
    }
  }, [props.showAddSuccessMessage]);
  return (
    <React.Fragment >
      <Button color="primary" onClick={toggleCanvas}>
        <i className="bx bx-filter me-1" />
        {filtered ? props.t("Update Filter") : props.t("Advance Filter")}
      </Button>
      {filtered &&
        <Button color="primary" className="ms-1" onClick={resetFilter}>
          {props.t("Clear Filter")}
        </Button>
      }
      <Offcanvas
        isOpen={openCanvas}
        direction="end"
        scrollable
        toggle={toggleCanvas}
      >
        <OffcanvasHeader toggle={toggleCanvas}
          tag="h4">
          {props.t("Advance Filter")}
        </OffcanvasHeader>
        <OffcanvasBody>
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              props.filterChangeHandler(values);
              setOpenCanvas(false);
            }}
          >
            {/* user name */}
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <SearchableAgentDropdown
                    isRequired={false}
                    value={props.filteredValues?.type || values.agent}
                    // isMulti={true}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        agent: e.value,
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className="mb-3">
              <ClientDropDown
                defaultValue={props.filteredValues?.customerId || values.customerId}
                clientChangeHandler={clientChangeHandler}
              />
            </div>
            {/* date */}
            <div className="mb-3">
              <FromToDate
                filterDate={values.filterDate}
                dateChangeHandler={dateChangeHandler}
              />
            </div>

            <div className="row">
              <div className="col-sm-12 text-center">
                <Button disabled={!filtered} type="submit" color="primary" className="btn btn-primary btn-md center-block">
                  {props.t("Filter")}
                </Button>
                {" "}
                {" "}
                <Button disabled={!filtered} onClick={resetFilter} color="primary" type="reset" className="btn btn-danger btn-md center-block">
                  {props.t("Reset")}
                </Button>
              </div>
            </div>

          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.t(props.error)}
          </UncontrolledAlert>}
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  clientDetails: state.clientReducer.clientDetails || {},
  todos: state.logsReducer.logs && state.logsReducer.logs.docs || [],
  pagination: state.logsReducer.logs || {},
  loading: state.logsReducer.loading,
  selectedClient: state.clientReducer.clientDetails || {},
});
export default connect(mapStateToProps, null)(withTranslation()(Filter));