import {
  FETCH_MM_DEPOSITS_REQUESTED,
  FETCH_MM_DEPOSITS_SUCCESS,
  FETCH_MM_DEPOSITS_FAIL,

  ADD_MM_DEPOSIT_REQUESTED,
  ADD_MM_DEPOSIT_SUCCESS,
  ADD_MM_DEPOSIT_FAIL,
  ADD_MM_DEPOSIT_CLEAR,
  ADD_MM_DEPOSIT_ERROR_CLEAR,
  APPROVE_MM_DEPOSIT,
  APPROVE_MM_DEPOSIT_SUCCESS,
  REJECT_MM_DEPOSIT_SUCCESS,

  FETCH_CLIENT_SUBSCRIPTIONS_SUCCESS,
} from "./actionTypes";

const initalState = {
  mmDeposits: [],
  fetchLoading: false,
  addLoading: false,
  error: "",
  modalClear: false,
  mmDepositResponseMessage: "",
  addMMDepositClearingCounter: 0
};

const mmDepositReducer = (state = initalState, action) => {
  switch (action.type) {
    // fetch mm deposits
    case FETCH_MM_DEPOSITS_REQUESTED:
      state = {
        ...state,
        fetchLoading: true
      };
      break;
    case FETCH_MM_DEPOSITS_SUCCESS:
      state = {
        ...state,
        mmDeposits: action.payload.result,
        totalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        fetchLoading: false,
      };
      break;
    case FETCH_CLIENT_SUBSCRIPTIONS_SUCCESS:
      state = {
        ...state,
        clientSubscriptions: action.payload.result,
      };
      break;
    case FETCH_MM_DEPOSITS_FAIL:
      state = {
        ...state,
        fetchLoading: false,
        mmDepositError: action.payload
      };
      break;

    // add mm deposit
    case ADD_MM_DEPOSIT_REQUESTED:
      state = {
        ...state,
        addLoading: true,
        addMMDepositSuccess: false,
        addMMDepositFail: false,
        addMMDepositFailDetails: ""
      };
      break;
    case ADD_MM_DEPOSIT_SUCCESS:
      state = {
        ...state,
        newMMDeposit: action.payload.result,
        addMMDepositSuccess: true,
        addMMDepositFail: false
      };
      break;
    case ADD_MM_DEPOSIT_FAIL:
      state = {
        ...state,
        addMMDepositSuccess: false,
        addMMDepositFail: true,
        addLoading: false,
        addMMDepositFailDetails: action.payload.error
      };
      break;
    case ADD_MM_DEPOSIT_CLEAR:
      state = {
        ...state,
        addLoading: false,
        addMMDepositClearingCounter: state.addMMDepositClearingCounter + 1,
        addMMDepositFail: false,
        addMMDepositSuccess: false,
        modalClear: true,
        addMMDepositFailDetails: ""
      };
      break;
    case ADD_MM_DEPOSIT_ERROR_CLEAR:
      state = {
        ...state,
        addMMDepositFail: false,
        addMMDepositSuccess: false,
        addMMDepositFailDetails: null
      };
      break;
    case APPROVE_MM_DEPOSIT:
      return {
        ...state,
      };
    case APPROVE_MM_DEPOSIT_SUCCESS:
      return {
        ...state,
        mmDeposits: state.mmDeposits.map(d => {
          if (d._id === action.payload) {
            d.status = "APPROVED";
          }
          return d;
        }),
      };
    case REJECT_MM_DEPOSIT_SUCCESS:
      return {
        ...state,
        mmDeposits: state.mmDeposits.map(d => {
          if (d._id === action.payload) {
            d.status = "REJECTED";
          }
          return d;
        }),
      };
    default:
      state = { ...state };
  }
  return state;
};

export default mmDepositReducer;