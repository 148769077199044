import { cpUrl } from "content";

export default [
  {
    type: "Register Live",
    url: `${cpUrl}register/gold/live`
  },
  {
    type: "Register Demo",
    url: `${cpUrl}register/gold/demo`
  },
  // {
  //   type: "Register Sub IB",
  //   url: `${cpUrl}register/trading/ib`
  // }
];
