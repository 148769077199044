import * as axiosHelper from "./api_helper";
import qs from "qs";

// fetch MM withdrawals
export const getMMWithdraws = async ({ payload }) => {
  const result = await axiosHelper.get(`/mmtransactions/withdraw?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

// add MM withdrawal
export const postMMWithdraw = async ({ payload }) => {
  const result = await axiosHelper.post("/mmtransactions/withdraw", payload);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const approveMMWithdraw = async (id, customerId) => {
  const result = await axiosHelper.patch(`/mmtransactions/withdraw/${id}/approve`, { customerId:customerId });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const rejectMMWithdraw = async (id, customerId)=>{
  const result = await axiosHelper.patch(`/mmtransactions/withdraw/${id}/reject`, { customerId:customerId });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};