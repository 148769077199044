import React, { 
  useState,
  useEffect,
} from "react";
import { withTranslation } from "react-i18next";
import {
  useDispatch, useSelector
} from "react-redux";
import moment from "moment";
import {
  Button,
  Row, Col, 
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CustomPagination from "components/Common/CustomPagination";

import Loader from "components/Common/Loader";
import { fetchReports } from "store/gold/report/actions";
import { fetchAssetsStart } from "store/assests/actions";

function ProductsFilter(props) {
  const dispatch = useDispatch();
  const [sizePerPage, setSizePerPage] = useState(10);
  const [selectedValues, setSelectedValues] = useState({});
  const [reportType, setReportType] = useState("");

  const submitReportSearch = (e, values) => {
    props.setReportColumns(reportType);
    setSelectedValues(values);
    dispatch(fetchReports(values));
  };
  const paginationLoad = (page) => {
    dispatch(fetchReports({
      ...selectedValues,
      page,
      limit: sizePerPage
    }));
  };
  useEffect(()=>{
    if (selectedValues && selectedValues.reportType) {
      dispatch(fetchReports({
        ...selectedValues,
        page: 1,
        limit: sizePerPage,
      }));
    }
  }, [sizePerPage]);

  const { assets } = useSelector(state => state.assetReducer);

  useEffect(() => {
    if (!assets || !assets.length) {
      dispatch(fetchAssetsStart());
    }
  }, []);

  const listOptions = (data) => {
    return data.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
  };

  const defaultValues = {
    reportType: "deposits",
    dateFrom: moment().subtract(1, "months").format("YYYY-MM-DD"),
    dateTo: moment().add(1, "day").format("YYYY-MM-DD"),
  };

  const { reportsPagination, loading } = useSelector(state => state.goldReducer.reports);
  const { salesAgent } = useSelector(state => state.usersReducer);


  return (
    <React.Fragment >
      <div className=" me-2 mb-2">
        <div className="position-relative">
          <AvForm
            className='p-1'
            onValidSubmit={(e, v) => {
              submitReportSearch(e, v);
            }}
            model={defaultValues}
          >
            <Row >
              <Col className="col-2 mb-3">
                <label >Select Report Type </label>
                <AvField
                  type="select"
                  name="reportType"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  errorMessage={props.t("Please select Report Type")}
                  validate={{ required: { value: true } }}
                >
                  <option value="">{props.t("Select Report")}</option>
                  <option value="all">{props.t("All")}</option>
                  <option value="delivery">{props.t("Delivery Products")}</option>
                  <option value="storage">{props.t("Stored Products")}</option>
                  <option value="sell-back">{props.t("Sell Back Products")}</option>
                </AvField>
              </Col>
              <Col className="col-2 mb-3">
                <label >Select Currency</label>
                <AvField
                  type="select"
                  name="currency"
                  errorMessage={props.t("Please Select Currency")}
                  validate={{ required: { value: true } }}
                >
                  <option value="">{props.t("Select Currency")}</option>
                  <option value="ALL">{props.t("ALL")}</option>
                  {assets?.filter(asset => !asset?.isInventory).map((obj, index) => <option key={index} value={obj.name}>{obj.name}</option>)}
                </AvField>
              </Col>
              <Col  className="col-2 mb-3">
                <AvField
                  name="dateFrom"
                  label={props.t("Date From")}
                  type="date"
                  errorMessage={props.t("Select date From")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col className="col-2 mb-3">
                <AvField
                  name="dateTo"
                  label={props.t("Date To")}
                  type="date"
                  errorMessage={props.t("Select date To")}
                  validate={{ required: { value: true } }}
                />
              </Col>  
              <Col className="col-2 mb-3">
                <label > Agent </label>
                <AvField
                  type="select"
                  name="agent"
                  errorMessage={props.t("Select Agent")}
                >
                  <option value="">{props.t("All")}</option>
                  {salesAgent?.map((obj, index) => <option key={index} value={obj._id}>{`${obj.firstName} ${obj.lastName}`}</option>)}
                  <option value="deposits">{props.t("Deposits")}</option>
                </AvField>
              </Col>
              <Col className="col-2 mb-3">
                <label > Status </label>
                <AvField
                  type="select"
                  name="status"
                  defaultValue={""}
                  errorMessage={props.t("Status")}
                >
                  {
                    listOptions([
                      {
                        id: "",
                        name: props.t("All"),
                      },
                      {
                        id: "PENDING",
                        name: props.t("Pending"),
                      },
                      {
                        id: "IN_PROGRESS",
                        name: props.t("In Progress"),
                      },
                      {
                        id: "REJECT",
                        name: props.t("Rejected"),
                      },
                      {
                        id: "DELIVERED",
                        name: props.t("Delivered"),
                      },
                      {
                        id: "APPROVE",
                        name: props.t("Approved"),
                      },
                    ])
                  }
                </AvField>
              </Col>
              <Col className='col-12 text-center d-flex justify-content-center align-items-center mt-2'>
                {
                  loading
                    ?
                    <Loader />
                    :
                    <Button type="submit" color="primary" className="" disabled={loading}>
                      {props.t("Search")} 
                    </Button>
                }
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0"
          data-pattern="priority-columns"
        >
          {props.children}
          <CustomPagination
            {...reportsPagination}
            setSizePerPage={setSizePerPage}
            sizePerPage={sizePerPage}
            onChange={paginationLoad}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default (withTranslation()(ProductsFilter));
