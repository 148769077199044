import {
  CREATE_MAM_PROFILE_FAIL,
  CREATE_MAM_PROFILE_START,
  CREATE_MAM_PROFILE_SUCCESS,
  FETCH_MAM_PROFILE_FAIL,
  FETCH_MAM_PROFILE_START,
  FETCH_MAM_PROFILE_SUCCESS,
  LINK_INVESTOR_TO_PROFILE_FAIL,
  LINK_INVESTOR_TO_PROFILE_START,
  LINK_INVESTOR_TO_PROFILE_SUCCESS,
  LINK_MASTER_TO_INVESTOR_FAIL,
  LINK_MASTER_TO_INVESTOR_START,
  LINK_MASTER_TO_INVESTOR_SUCCESS,
  UNLINK_INVESTOR_FROM_PROFILE_FAIL,
  UNLINK_INVESTOR_FROM_PROFILE_START,
  UNLINK_INVESTOR_FROM_PROFILE_SUCCESS,
  UNLINK_MASTER_FROM_INVESTOR_FAIL,
  UNLINK_MASTER_FROM_INVESTOR_START,
  UNLINK_MASTER_FROM_INVESTOR_SUCCESS,
} from "./actionTypes";

export const fetchMamProfiles = (data) => {
  return {
    type: FETCH_MAM_PROFILE_START,
    payload: data,
  };
};

export const fetchMamProfilesSuccess = (data) => {
  return {
    type: FETCH_MAM_PROFILE_SUCCESS,
    payload: data,
  };
};

export const fetchMamProfilesFail = (error) => {
  return {
    type: FETCH_MAM_PROFILE_FAIL,
    payload: error,
  };
};

export const createMamProfile = (payload) => {
  return {
    type: CREATE_MAM_PROFILE_START,
    payload: payload,
  };
};

export const createMamProfileSuccess = (data) => {
  return {
    type: CREATE_MAM_PROFILE_SUCCESS,
    payload: data,
  };
};

export const createMamProfileFail = (error) => {
  return {
    type: CREATE_MAM_PROFILE_FAIL,
    payload: error,
  };
};

export const linkInvestorToProfile = (payload) => {
  return {
    type: LINK_INVESTOR_TO_PROFILE_START,
    payload: payload,
  };
};

export const linkInvestorToProfileSuccess = (data) => {
  return {
    type: LINK_INVESTOR_TO_PROFILE_SUCCESS,
    payload: data,
  };
};

export const linkInvestorToProfileFail = (error) => {
  return {
    type: LINK_INVESTOR_TO_PROFILE_FAIL,
    payload: error,
  };
};

export const unlinkInvestorFromProfile = (payload) => {
  return {
    type: UNLINK_INVESTOR_FROM_PROFILE_START,
    payload: payload,
  };
};

export const unlinkInvestorFromProfileSuccess = (data) => {
  return {
    type: UNLINK_INVESTOR_FROM_PROFILE_SUCCESS,
    payload: data,
  };
};

export const unlinkInvestorFromProfileFail = (error) => {
  return {
    type: UNLINK_INVESTOR_FROM_PROFILE_FAIL,
    payload: error,
  };
};

export const linkMasterToInvestor = (payload) => {
  return {
    type: LINK_MASTER_TO_INVESTOR_START,
    payload: payload,
  };
};

export const linkMasterToInvestorSuccess = (data) => {
  return {
    type: LINK_MASTER_TO_INVESTOR_SUCCESS,
    payload: data,
  };
};

export const linkMasterToInvestorFail = (error) => {
  return {
    type: LINK_MASTER_TO_INVESTOR_FAIL,
    payload: error,
  };
};

export const unlinkMasterFromInvestor = (payload) => {
  return {
    type: UNLINK_MASTER_FROM_INVESTOR_START,
    payload: payload,
  };
};

export const unlinkMasterFromInvestorSuccess = (data) => {
  return {
    type: UNLINK_MASTER_FROM_INVESTOR_SUCCESS,
    payload: data,
  };
};

export const unlinkMasterFromInvestorFail = (error) => {
  return {
    type: UNLINK_MASTER_FROM_INVESTOR_FAIL,
    payload: error,
  };
};
