import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  useDispatch, useSelector, connect
} from "react-redux";
import {
  Row, Col,
  Modal, ModalHeader, ModalBody,
  Label, Button,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { fetchStrategy } from "store/strategy/actions";
import {
  addMMDeposit, fetchClientSubscriptions,
} from "store/moneyManagementTransactions/deposits/actions";
// import { fetchWalletStart, clearWallets } from "store/wallet/action";
import { fetchClientsStart } from "store/client/actions";
import "./SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import Loader from "components/Common/Loader";
import * as clientApi from "apis/client";
import { debounce } from "lodash";

function DepositForm(props){
  const [addModal, setDepositModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [strategy, setStrategy] = useState("");
  const [depositId, setDepositId] = useState("");
  const [type, setType] = useState("STRATEGY_DEPOSIT");
  const dispatch = useDispatch();
  const { create } = props.depositsPermissions;
  const [searchInput, setSearchInput]  = useState("");

  const { clientSubscriptions } = useSelector((state) => state.mmDepositReducer );

  const handleAddDeposit = (event, values) => {
    event.preventDefault();
    dispatch(addMMDeposit({
      customerId: selectedClient,
      walletId: selectedWalletId,
      depositId: depositId,
      strategyId: strategy,
      type,
      ...values
    }));
    setSearchInput("");
    // dispatch(clearWallets());
  }; 
  
  const toggleAddModal = () => {
    setDepositModal(!addModal);
  };
  useEffect(()=>{
    dispatch(fetchClientsStart({
      page: 1,
      type: "LIVE"
    }));
    dispatch(fetchStrategy());
    if (searchInput.length >= 3){
      dispatch(fetchClientsStart({
        searchText: searchInput,
        type: "LIVE"
      }));
    }
  
  }, [searchInput]);


  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      clientApi.getClients({
        payload: {
          searchText: inputValue,
          type: "LIVE"
        }
      }).then((data) => {
        return cb(data?.result?.docs.map((item) => (
          {
            label : `${item.firstName} ${item.lastName}`,
            value : {
              name: `${item.firstName} ${item.lastName}`,
              id: `${item._id}`
            }
          }
        )));
      });
    }, 1000), []
  );

  useEffect(() => {
    if (selectedClient && strategy)
      dispatch(fetchClientSubscriptions({
        type: "STRATEGY_DEPOSIT",
        customerId: selectedClient,
        strategyId: strategy,
      }));
  }, [selectedClient, strategy]);

  useEffect(() => {
    if (!props.disableAddButton && open ){
      setDepositModal(false);
    }
  }, [props.modalClear]);
  
  const selectClient = (id)=>{
    setSelectedClient(id);
  //   dispatch(fetchWalletStart({
  //     belongsTo:id,
  //     customerId:id,
  //   }));
  };

  const selectType = (type)=>{
    setType(type);
  };

  const validateBiggerThanZero = (value, ctx, input, cb) =>{
    if (value == 0){
      cb("Should be bigger than 0");
    } else
      cb(true);
  };

  return (
    <React.Fragment >
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> {props.t("Add New Deposit")}</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add Deposit")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4 py-2'
            onValidSubmit={(e, v) => {
              delete v.client;
              delete v.clientId;
              delete v.wallet;
              // delete v.strategy;
              handleAddDeposit(e, v);
            }}
          >
            <Row className="mb-3">
              <Col md="6">                      
                <Label>{props.t("Client")}</Label>
                <div>
                  <AsyncSelect
                    isClearable
                    placeholder = {props.t("Choose A Client")}
                    defaultOptions={props.loading ? [] : props.clients.map((item) => (
                      {
                        label : `${item.firstName} ${item.lastName}`,
                        value : {
                          name: `${item.firstName} ${item.lastName}`,
                          id: `${item._id}`
                        }
                      }
                    ))}
                    classNamePrefix="select2-selection"
                    loadOptions={debouncedChangeHandler}
                    onChange={(e) => { 
                      if (e && e.value && e.value.id) {
                        selectClient(e.value.id);
                      }
                    }}
                    isRequired={true}
                    isSearchable={true}
                    backspaceRemoves={true}
                    name="clientId"
                  />
                  {/* <Select 
                    onChange={(e) => { 
                      selectClient(e.value.id);
                    }}
                    isSearchable = {true}
                    options={props.loading ? [] : props.clients.map((item) => (
                      {
                        label : `${item.firstName} ${item.lastName}`,
                        value : {
                          name: `${item.firstName} ${item.lastName}`,
                          id: `${item._id}`
                        }
                      }
                    ))}
                    classNamePrefix="select2-selection"
                    placeholder = {props.t("Choose A Client")}
                    name = "clientId"
                    isRequired = {true}
                    isLoading={props.loading}
                  /> */}
                  <AvField
                    name="clientId"
                    type="text"
                    errorMessage={props.t("Choose A Client")}
                    validate={{ required: { value: true } }}
                    value={selectedClient}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col>
              <Col md="6">
                <Label>{props.t("Type")}</Label> 
                <div>
                  <Select 
                    defaultValue={{
                      label: "Deposit Into Strategy",
                      value: "STRATEGY_DEPOSIT" 
                    }}
                    onChange={(e) => {
                      selectType(e.value);   
                    }}
                    options={[{
                      label: "Profit For Strategy",
                      value: "STRATEGY_PROFIT" 
                    }, {
                      label: "Deposit Into Strategy",
                      value: "STRATEGY_DEPOSIT" 
                    }]}
                    classNamePrefix="select2-selection"
                    placeholder = {props.t("Choose Deposit Type")}
                  />
                </div>
              </Col>

              <Col md="12" className="mt-3">
                <Label>{props.t("Wallet")}</Label>
                <div>
                  <Select 
                    onChange={(e) => {
                      setSelectedWalletId(e.value.id);
                    }}
                    isSearchable = {true}
                    options={props.wallets.map((wallet) => (
                      {
                        label : `${wallet.asset}-(Balance ${wallet.amount} ${wallet.asset})`,
                        value : {
                          id: `${wallet._id}`
                        }
                      }
                    ))}
                    name="wallet"
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose A Wallet")}
                  />
                  <AvField
                    name="wallet"
                    type="text"
                    errorMessage={props.t("Choose A Wallet")}
                    validate={{ required: { value: true } }}
                    value={selectedWalletId}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <Label>{props.t("Strategy")}</Label>
              <div>
                <Select
                  isSearchable = {true}
                  onChange={(e) => {
                    setStrategy(e.value);
                  }}
                  options={props.strategy.map((str) => (
                    {
                      label : `${str.name}`,
                      value : str._id,
                    }
                  ))}
                  classNamePrefix="select2-selection"
                  placeholder={props.t("Choose A Strategy")}    
                />
              </div>
            </div>
            {type === "STRATEGY_PROFIT" &&
              <div className="mb-3">
                <Label>{props.t("Subscription")}</Label>
                <div>
                  <Select
                    isSearchable = {true}
                    onChange={(e) => {
                      setDepositId(e.value);
                    }}
                    options={clientSubscriptions?.map((str) => (
                      {
                        label : `${str.recordId} — ${moment(str.approvedDate).format("YYYY-MM-DD")}`,
                        value : str._id,
                      }
                    ))}
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose A Subscription")}    
                  />
                </div>
              </div>}
            <div className="mb-3">
              <AvField
                name="amount"
                label={props.t("Amount")}
                placeholder={props.t("Enter An Amount")}
                type="number"
                min="1"
                errorMessage={props.t("Enter Valid Amount")}
                validate = {{
                  required :{ value:true },
                  pattern : {
                    // eslint-disable-next-line no-useless-escape
                    value :"^[0-9]+(\\.([0-9]{1,4}))?$",
                    errorMessage : "Amount is not valid"
                  },
                  custom:validateBiggerThanZero
                }}
              />
            </div>

            <div className="mb-3">
              <AvField
                name="note"
                label={props.t("Note")}
                placeholder={props.t("Enter Note")}
                type="text"
              />
            </div>
    
            <div className='text-center mt-3 p-2'>
              {
                props.addLoading 
                  ?
                  <Loader />
                  :
                  <Button 
                    disabled = {props.addLoading} 
                    type="submit" 
                    color="primary"
                  >
                    {props.t("Add")}
                  </Button>
              }
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.t(props.error)}
          </UncontrolledAlert>}
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  gateways: state.gatewayReducer.gateways || [],
  strategy: state.strategyReducer.strategy || [],
  modalClear: state.mmDepositReducer.modalClear,
  depositResponseMessage: state.mmDepositReducer.depositResponseMessage,
  clients: state.clientReducer.clients || [],
  clientOptions: state.clientReducer.clients && state.clientReducer.clients.length > 0 && state.clientReducer.clients.map((item) => (
    {
      label : `${item.firstName} ${item.lastName}`,
      value : {
        name: `${item.firstName} ${item.lastName}`,
        id: `${item._id}`
      }
    }
  )),
  wallets: state.walletReducer.wallets || [],
  depositsPermissions: state.Profile.depositsPermissions || {}, 
  disableAddButton: state.mmDepositReducer.disableAddButton,
  addLoading: state.mmDepositReducer.addLoading
});
export default connect(mapStateToProps, {
  getClients: fetchClientsStart,
})(withTranslation()(DepositForm));