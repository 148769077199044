import {
  CREATE_MAM_PROFILE_FAIL,
  CREATE_MAM_PROFILE_START,
  CREATE_MAM_PROFILE_SUCCESS,
  FETCH_MAM_PROFILE_FAIL,
  FETCH_MAM_PROFILE_START,
  FETCH_MAM_PROFILE_SUCCESS,
  LINK_INVESTOR_TO_PROFILE_FAIL,
  LINK_INVESTOR_TO_PROFILE_START,
  LINK_INVESTOR_TO_PROFILE_SUCCESS,
  LINK_MASTER_TO_INVESTOR_FAIL,
  LINK_MASTER_TO_INVESTOR_START,
  LINK_MASTER_TO_INVESTOR_SUCCESS,
  UNLINK_INVESTOR_FROM_PROFILE_FAIL,
  UNLINK_INVESTOR_FROM_PROFILE_START,
  UNLINK_INVESTOR_FROM_PROFILE_SUCCESS,
  UNLINK_MASTER_FROM_INVESTOR_FAIL,
  UNLINK_MASTER_FROM_INVESTOR_START,
  UNLINK_MASTER_FROM_INVESTOR_SUCCESS,
} from "./actionTypes";

const initialState = {
  profile: {
    loading: false,
    profiles: [],
    error: "",
  },
  createProfile: {
    loading: false,
  },
  link: {
    loading: false,
  },
  clearingCounter: 0,
};
const mamPamReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAM_PROFILE_START:
      state = {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
          profiles: [],
        },
      };
      break;
    case FETCH_MAM_PROFILE_SUCCESS:
      state = {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          profiles: action.payload,
        },
      };
      break;
    case FETCH_MAM_PROFILE_FAIL:
      state = {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
          error: action.payload,
        },
      };
      break;
    case CREATE_MAM_PROFILE_START:
      state = {
        ...state,
        createProfile: {
          ...state.createProfile,
          loading: true,
        },
      };
      break;
    case CREATE_MAM_PROFILE_SUCCESS:
      state = {
        ...state,
        createProfile: {
          ...state.createProfile,
          loading: false,
        },
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case CREATE_MAM_PROFILE_FAIL:
      state = {
        ...state,
        createProfile: {
          ...state.createProfile,
          loading: false,
        },
      };
      break;
    case LINK_INVESTOR_TO_PROFILE_START:
    case UNLINK_INVESTOR_FROM_PROFILE_START:
    case LINK_MASTER_TO_INVESTOR_START:
    case UNLINK_MASTER_FROM_INVESTOR_START:
      state = {
        ...state,
        link: {
          ...state.link,
          loading: true,
        },
      };
      break;
    case LINK_INVESTOR_TO_PROFILE_SUCCESS:
    case UNLINK_INVESTOR_FROM_PROFILE_SUCCESS:
    case LINK_MASTER_TO_INVESTOR_SUCCESS:
    case UNLINK_MASTER_FROM_INVESTOR_SUCCESS:
      state = {
        ...state,
        link: {
          ...state.link,
          loading: false,
        },
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case LINK_INVESTOR_TO_PROFILE_FAIL:
    case UNLINK_INVESTOR_FROM_PROFILE_FAIL:
    case LINK_MASTER_TO_INVESTOR_FAIL:
    case UNLINK_MASTER_FROM_INVESTOR_FAIL:
      state = {
        ...state,
        link: {
          ...state.link,
          loading: false,
        },
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default mamPamReducer;