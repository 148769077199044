export function extractNumbers(num) {
  return num.replace(/^\D+/g, "");
}


export function validateWith2Decimals(event) {
  const value = event.target.value;
  // Allow the input of decimal point and up to two decimal places
  if (!/^\d*\.?\d{0,2}$/.test(value + event.key)) {
    event.preventDefault();
  }
  // Allow only one period in the input
  if (value.includes(".") && event.key === ".") {
    return true;
  }

}
export function validateIntegers(event) {
  const value = event.target.value;
  // Allow the input of decimal point and up to two decimal places
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
  // Allow only one period in the input
  if (value.includes(".") && event.key === ".") {
    event.preventDefault();
  }

}

export default (event) => {
  if (event.key == ".")
    return true;
  if (!/[0-9]/.test(event.key))
    event.preventDefault();
};