import React, {
  useEffect, useReducer, useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  ModalFooter
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { fetchIbParents } from "store/client/actions";
import { createSharedIbAgreement } from "store/actions";
import AgreementItem from "./AgreementItem";
import useModal from "hooks/useModal";
import AddSubIbModal from "./AddSubIbModal";

const AddAdvancedSharedIbModal = ({
  show,
  toggle : toggleModal,
  accountTypes: allAccountTypes,
  products,
  clientId,
  markups,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showAddIb, toggleAddIb] = useModal();
  const [isEditAvailable, setIsEditAvailable] = useState(false);

  const { parents: ibParents } = useSelector(
    (state) => state.clientReducer?.clientDetails
  );

  const { submitting, clearingCounter } = useSelector((state) => state.ibAgreements);

  const [parents, setParents] = useState([]);

  const [agreement, setAgreement] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_TITLE":
          return {
            ...state,
            title: action.payload,
          };
        case "SET_TOTALS":
          return {
            ...state,
            totals: action.payload,
          };
        case "UPDATE_TOTALS":
          return {
            ...state,
            totals: state.totals.map((total) => {
              if (total.accountTypeId === action.payload.accountTypeId) {
                return {
                  ...total,
                  ...action.payload,
                };
              }
              return total;
            }),
            members: state.members.map((member) => {
              return {
                ...member,
                isValid: false,
              };
            }),
          };
        case "SET_MEMBERS":
          return {
            ...state,
            members: action.payload,
          };
        case "UPDATE_MEMBER": 
          return {
            ...state,
            members: state.members.map((member) => {
              if (member.customerId === action.payload.customerId) {
                return {
                  ...member,
                  ...action.payload,
                };
              }
              return member;
            }),
          };
        case "ADD_MEMBER":
          return {
            ...state,
            members: [...state.members, action.payload],
          };
        case "REMOVE_MEMBER":
          return {
            ...state,
            members: state.members.filter(
              (member) => member.customerId !== action.payload.customerId
            ),
          };
        default:
          return state;
      }
    },
    {
      title: "",
      totals: [],
      members: [],
    }
  );

  useEffect(() => {
    clientId && dispatch(fetchIbParents({ clientId }));
  }, [clientId]);

  useEffect(() => {
    if (ibParents) {
      setParents(ibParents?.[0]);
    }
  }, [ibParents]);

  useEffect(() => {
    if (allAccountTypes) {
      const newTotals = [];
      allAccountTypes?.filter(
        (a) => a.type === "LIVE"
      )?.forEach((acc) => {
        newTotals.push({
          accountTypeId: acc._id,
          rebate: null,
          commission: null,
        });
      });
      setAgreement({
        type: "SET_TOTALS",
        payload: newTotals,
      });
    }
  }, [allAccountTypes]);

  const initMembers = () => {
    if (parents?.length > 0) {
      const newMembers = [];
      parents?.forEach((parent) => {
        const { members } = agreement;
        const member = members.find(
          (m) => m.customerId === parent._id
        );
        if (member?.isValid) {
          newMembers.push({
            ...member
          });
        } else {
          const newValues = [];
          allAccountTypes?.filter(
            (a) => a.type === "LIVE"
          ).forEach((acc) => {
            const newProducts = {};
            for (const prod in products) {
              const key = products[prod];
              newProducts[key] = {
                rebate: null,
                commission: null,
              };
            }
            newValues.push({
              accountTypeId: acc._id,
              rebate: null,
              commission: null,
              products: newProducts,
            });
          });
          newMembers.push({
            customerId: parent._id,
            level: parent.level,
            values: newValues,
            isValid: parent?.isValid ? parent.isValid : false,
            rebate: null,
            commission: null,
          });
        }
      });
      setAgreement({
        type: "SET_MEMBERS",
        payload: newMembers,
      });
    }
  };

  useEffect(() => {
    initMembers();
  }, [parents, products, parents]);

  useEffect(() => {
    const {
      totals, 
    } = agreement;
    if (totals && totals?.length > 0) {
      const isTotalsValid = totals.every((total) => {
        return (total.rebate !== null && !isNaN(total.rebate)
        ) && (total.commission !== null && !isNaN(total.commission));
      });
      if (isTotalsValid) {
        setIsEditAvailable(true);
      } else {
        setIsEditAvailable(false);
      }
    }
  }, [agreement?.totals]);

  const isCustomersValid = agreement.members.every((member) => member.isValid);

  // totals should be the subtraction of totals rebate and commission minus the valid members rebate and commission for each account type
  const totals = agreement.totals.map((total) => {
    const validMembers = agreement.members.filter((member) => member.isValid);
    const validMembersTotal = validMembers.reduce((acc, member) => {
      const memberTotal = member.values.find(
        (value) => value.accountTypeId === total.accountTypeId
      );
      return {
        rebate: acc.rebate + memberTotal.rebate,
        commission: acc.commission + memberTotal.commission,
      };
    }, {
      rebate: 0,
      commission: 0
    });
    return {
      ...total,
      rebate: total.rebate - validMembersTotal.rebate,
      commission: total.commission - validMembersTotal.commission,
    };
  });

  const toggle = () => {
    dispatch(fetchIbParents({ clientId }));
    setAgreement({
      type: "SET_TITLE",
      payload: "",
    });
    if (allAccountTypes) {
      const newTotals = [];
      allAccountTypes?.filter(
        (a) => a.type === "LIVE"
      )?.forEach((acc) => {
        newTotals.push({
          accountTypeId: acc._id,
          rebate: null,
          commission: null,
        });
      });
      setAgreement({
        type: "SET_TOTALS",
        payload: newTotals,
      });
    }
    toggleModal();
  };
  
  useEffect(() => {
    if (show && !submitting && clearingCounter > 0) toggle();
  }, [clearingCounter]);

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered
      size="lg"
    >
      <AvForm
        validationEvent={["onChange", "onInput", "onBlur"]}
        onValidSubmit={() => {

          dispatch(
            createSharedIbAgreement({
              ...agreement,
              members: agreement.members.map((member) => {
                return {
                  ...member,
                  isValid: undefined,
                  rebate: undefined, 
                  commission: undefined,
                };
              }),
            }),
          );
        }}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {t("New Shared IB Agreement")}
        </ModalHeader>
        <ModalBody>
          <Row className="p-3">
            <Col md="6">
              <AvField
                name={"title"}
                label={t("Agreement name")}
                placeholder={t("Agreement name")}
                className="mb-3"
                value={agreement.title}
                type="text"
                errorMessage={t("Required!")}
                validate={{ required: { value: true } }}
                onChange={(e) =>
                  setAgreement({
                    type: "SET_TITLE",
                    payload: e.target.value,
                  })
                }
              />
            </Col>
            <Col md="6" className="px-3">
              <Row className="justify-content-start">
                <Col md="3">{t("Type")}</Col>
                <Col>{t("Total Rebate")}</Col>
                <Col>{t("Total Commission")}</Col>
              </Row>
              {agreement.totals.map((type, accIdx) => (
                <Row key={type._id} className="my-1 align-items-center">
                  <Col md="3">
                    {allAccountTypes && allAccountTypes.find((acc) => acc._id === type.accountTypeId)?.title}
                    <AvField
                      name={`totals.${accIdx}.accountTypeId`}
                      value={type.accountTypeId}
                      type="hidden"
                    />
                  </Col>
                  <Col>
                    <AvField
                      className="mt-3"
                      name={`totals[${accIdx}].rebate`}
                      value={type.rebate}
                      bsSize="sm"
                      type="number"
                      errorMessage={t("Invalid Rebate value!")}
                      validate={{
                        required: { value: true },
                        min: { value: 0 },
                      }}
                      onChange={(e) =>
                        setAgreement({
                          type: "UPDATE_TOTALS",
                          payload: {
                            accountTypeId: type.accountTypeId,
                            rebate: parseInt(e.target.value),
                          },
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <AvField
                      className="mt-3"
                      name={`totals[${accIdx}].commission`}
                      bsSize="sm"
                      type="number"
                      value={type.commission}
                      errorMessage={t("Invalid Commission value!")}
                      validate={{
                        required: { value: true },
                        min: { value: 0 },
                      }}
                      onChange={(e) =>
                        setAgreement({
                          type: "UPDATE_TOTALS",
                          payload: {
                            accountTypeId: type.accountTypeId,
                            commission: parseInt(e.target.value),
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              ))}
              <hr className="my-3" />
            </Col>
          </Row>
          <Row className="px-5 pb-3">
            <Col>
              <h4 className="text-center mb-3">{t("Agreement Members")}</h4>
              {agreement.members &&
                agreement.members
                  .sort((a, b) => a.level - b.level)
                  .map((member, memberIdx) => {
                    const customer = parents?.find(
                      (parent) => parent._id === member.customerId
                    );
                    return <AgreementItem
                      key={member.customerId}
                      member={member}
                      customer={customer}
                      memberIdx={memberIdx}
                      products={products}
                      setAgreement={setAgreement}
                      isEditAvailable={isEditAvailable}
                      isAgreementValid={member.isValid}
                      totals={totals}
                      setIsAgreementValid={(member) =>
                        setAgreement({
                          type: "UPDATE_MEMBER",
                          payload: member,
                        })
                      }
                      onDelete={(customerId) => {
                        const filtered = agreement.members.filter(
                          (m) => m.customerId !== customerId
                        ).map((m, idx) => {
                          return {
                            ...m,
                            level: idx,
                          };
                        });
                        setAgreement({
                          type: "SET_MEMBERS",
                          payload: filtered,
                        });
                        setParents((prev) => prev.filter(
                          (p) => p._id !== customerId
                        ).map((p, idx) => {
                          return {
                            ...p,
                            level: idx,
                          };
                        }));
                      }}
                      accountTypes={allAccountTypes?.filter(
                        (a) => a.type === "LIVE"
                      )}
                    />;
                  })}
            </Col>
          </Row>
          <Row className="mb-3">
            {/* Add button to add sub ib */}
            <Col className="d-flex justify-content-center">
              <Button
                color="primary"
                className="btn-sm"
                type="button"
                onClick={toggleAddIb}
              >
                <i className="mdi mdi-plus font-size-16"></i>
                <span className="ms-1">{t("Add Member")}</span>
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={toggle}
            disabled={submitting}
          >
            {t("Close")}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={submitting || !isCustomersValid} 
          >
            {submitting ? (
              <Spinner color="light" size="sm" />
            ) : (
              t("Save")
            )}
          </Button>
        </ModalFooter>
      </AvForm>
      {
        showAddIb && (
          <AddSubIbModal
            show={showAddIb}
            toggle={toggleAddIb}
            addIb={(ib) => {
              setAgreement({
                type: "ADD_MEMBER",
                payload: {
                  customerId: ib._id,
                  isValid: false,
                  rebate: null,
                  commission: null,
                  isNew: true,
                  values: agreement.totals.map((total) => {
                    const newProducts = {};
                    for (const prod in products) {
                      const key = products[prod];
                      newProducts[key] = {
                        rebate: null,
                        commission: null,
                      };
                    }
                    return {
                      accountTypeId: total.accountTypeId,
                      rebate: null,
                      commission: null,
                      products: newProducts,
                    };
                  }),
                },
              });
              setParents((prev) => [...prev, {
                ...ib,
                level: agreement.members.length,
              }]);
            }}
            disallow={agreement.members.map((member) => member.customerId)}
          />
        )
      }
    </Modal>
  );
};

export default AddAdvancedSharedIbModal;
