import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

function SharedModal(props) {

  const {
    isOpen,
    toggle,
    fields,
    title,
    submitHandler,
    clearingCounter,
    showButton,
    buttonTitle
  } = props;


  useEffect(() => {
    if (clearingCounter > 0) {
      setTimeout(() => {
        toggle();
      }, 1000);
    }
  }, [clearingCounter]);

  return (
    <>
      {
        showButton && (
          <Link to="#" className={"btn btn-primary"} onClick={toggle}>
            <i className="bx bx-plus me-1"></i>  {title}
          </Link>
        )
      }
      <Modal isOpen={isOpen} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {title}
        </ModalHeader>
        <ModalBody>
          <AvForm
            className="p-4"
            onValidSubmit={(e, v) => {
              submitHandler(e, v);
            }}
          >
            {
              fields.map((field, index) => (
                <React.Fragment key={index}>
                  {field}
                </React.Fragment>
              ) )
            }
            <div className="text-center ">
              <Button type="submit"  color="primary" className="">
                {buttonTitle}
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}
export default (SharedModal);
