import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";

import { Link } from "react-router-dom";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { withTranslation } from "react-i18next";

import { captilazeFirstLetter as capitalizeFirstLetter } from "common/utils/manipulateString";
import {
  cleanUp,
  fetchTradingRequests,
  tradingRequestApprove,
  tradingRequestReject
} from "store/requests/actions";
import Badge from "components/Common/Badge";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import FeatherIcon from "feather-icons-react";
import usePermissions from "routes/permissions";

function TradingRequest(props) {
  const columns = [
    {
      dataField: "recordId",
      text: props.t("Request Id"),
    },
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter: (val) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/clients/${val?.customerId?._id}/profile`,
                state: { clientId: val.customerId },
              }}
            >
              <i className="no-italics fw-bold">
                {val.customerId
                  ? `${capitalizeFirstLetter(
                    val.customerId.firstName
                  )} ${capitalizeFirstLetter(val.customerId.lastName)}`
                  : ""}
              </i>
            </Link>
          </div>
        );
      },
    },
    
    {
      dataField: "kyc",
      text: props.t("KYC Status"),
      formatter: (item) => {
        if (!item.customerId?.stages) return "Pending";
        const { kycApproved, kycRejected } = item.customerId?.stages;
        return (<div>
          <div className={kycApproved ? "text-success" : kycRejected ? "text-danger" : "text-warning"} >
            <i
              className={`mdi font-size-20 ${kycApproved ? "mdi-check-circle" : kycRejected ? "mdi-close-thick" : "mdi-alert-decagram-outline"}`}
              id={kycApproved ? "approve-icon" : kycRejected ? "reject-icon" : "pending-icon"}
            ></i>
          </div>
        </div>);
      }
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (user) => <Badge status={user.status}></Badge>,
    },
  ];
  const {
    requestsPermissions
  } = usePermissions();
  if (requestsPermissions.actions) {
    columns.push(
      {
        dataField: "",
        isDummyField: true,
        editable: false,
        text: props.t("Actions"),
        formatter: (item) => (
          <UncontrolledDropdown
            disabled={
              item.status !== "PENDING"
            }
          >
            <DropdownToggle
              tag="i"
              className="text-muted"
              style={{ cursor: "pointer" }}
            >
              <i
                className="mdi mdi-dots-horizontal font-size-18"
                style={{
                  color:
                  item.status !== "PENDING"
                    ? "lightgray"
                    : "rgb(66, 65, 65)",
                }}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => {
                  approveRequest(item?._id);
                }}
                href="#"
              >
                {props.t("Approve")}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  rejectRequest(item?._id); 
                }}
                href="#"
              >
                {props.t("Reject")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
      });
  }
  const {
    loading,
    clearingCounter,
  } = useSelector((state) => state.requestReducer);
  const {
    records, 
    pagination,
  } = useSelector((state) => state.requestReducer.trading);
  const [sizePerPage, setSizePerPage] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    
    loadTradingRequests(1, sizePerPage);
    return () => {
      dispatch(cleanUp());
    };
  }, [sizePerPage, 1, clearingCounter]);
  

  const loadTradingRequests = (page, limit) =>{
    dispatch(fetchTradingRequests({
      page,
      limit
    }));
  };

  const approveRequest = (id) =>{
    dispatch(
      tradingRequestApprove(id)
    );
  };

  const rejectRequest = (id) =>{
    dispatch(tradingRequestReject(id));
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Trading Requests
          </title>
        </MetaTags>
        <div className="container-fluid">
          <h2>Trading</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary"> Trading Portal Access Requests ({pagination?.totalDocs || 0})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => { loadTradingRequests(1, sizePerPage) }}
                    />
                  </CardTitle>
              
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            {columns.map((column, index) =>
                              <Th data-priority={index} key={index}>{column.text}</Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody className="text-center" style={{
                          fontSize: "12px",
                          textAlign: "center",
                          textTransform: "capitalize"
                        }}>
                          {loading && <TableLoader colSpan={4} />}
                          {!loading &&
                            records.length === 0  ? (
                              <>
                                <Tr>
                                  <Td colSpan={"100%"} className="fw-bolder text-center" st>
                                    <h4 className="fw-bolder text-center">No records</h4>
                                  </Td>
                                </Tr>
                              </>
                            ) :
                            records?.map((row, rowIndex) =>
                              <Tr key={rowIndex}>
                                {columns.map((column, index) =>
                                  <Td key={`${rowIndex}-${index}`}>
                                    { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                  </Td>
                                )}
                              </Tr>
                            )}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...pagination}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadTradingRequests}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
export default (withTranslation()(TradingRequest));
