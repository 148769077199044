// fetch MM deposits
export const FETCH_MM_WITHDRAW_REQUESTED = "FETCH_MM_WITHDRAW_REQUESTED";
export const FETCH_MM_WITHDRAW_SUCCESS = "FETCH_MM_WITHDRAW_SUCCESS";
export const FETCH_MM_WITHDRAW_FAIL = "FETCH_MM_WITHDRAW_FAIL";

export const ADD_MM_WITHDRAW_REQUESTED = "ADD_MM_WITHDRAW_REQUESTED";
export const ADD_MM_WITHDRAW_SUCCESS = "ADD_MM_WITHDRAW_SUCCESS";
export const ADD_MM_WITHDRAW_FAIL = "ADD_MM_WITHDRAW_FAIL";
export const ADD_MM_WITHDRAW_CLEAR = "ADD_MM_WITHDRAW_CLEAR";
export const ADD_MM_WITHDRAW_ERROR_CLEAR = "ADD_MM_WITHDRAW_ERROR_CLEAR";
export const APPROVE_MM_WITHDRAW = "APPROVE_MM_WITHDRAW";
export const APPROVE_MM_WITHDRAW_SUCCESS = "APPROVE_MM_WITHDRAW_SUCCESS";
export const REJECT_MM_WITHDRAW = "REJECT_MM_WITHDRAW";
export const REJECT_MM_WITHDRAW_SUCCESS = "REJECT_MM_WITHDRAW_SUCCESS";