import {
  FETCH_MM_WITHDRAW_REQUESTED,
  FETCH_MM_WITHDRAW_SUCCESS,
  FETCH_MM_WITHDRAW_FAIL,

  ADD_MM_WITHDRAW_REQUESTED,
  ADD_MM_WITHDRAW_SUCCESS,
  ADD_MM_WITHDRAW_FAIL,
  ADD_MM_WITHDRAW_CLEAR,
  ADD_MM_WITHDRAW_ERROR_CLEAR,
  APPROVE_MM_WITHDRAW,
  REJECT_MM_WITHDRAW
} from "./actionTypes";


// fetch mm withdraw
export const fetchMMWithdraw = (params = {}) => {
  return {
    type: FETCH_MM_WITHDRAW_REQUESTED,
    payload: params
  };
};
export const fetchMMWithdrawSuccess = (data) => {
  return {
    type: FETCH_MM_WITHDRAW_SUCCESS,
    payload: data
  };
};
export const fetchMMWithdrawFail = (error) => {
  return {
    type: FETCH_MM_WITHDRAW_FAIL,
    payload: { error }
  };
};

export const addMMWithdraw = (params = {}) => {
  return {
    type: ADD_MM_WITHDRAW_REQUESTED,
    payload: params
  };
};
export const addMMWithdrawSuccess = (data) => {
  return {
    type: ADD_MM_WITHDRAW_SUCCESS,
    payload: data
  };
};
export const addMMWithdrawFail = (error) => {
  return {
    type: ADD_MM_WITHDRAW_FAIL,
    payload: { error }
  };
};
export const addMMWithdrawClear = (data) => {
  return {
    type: ADD_MM_WITHDRAW_CLEAR,
    payload: data
  };
};
export const addMMWithdrawErrorClear = () => {
  return {
    type: ADD_MM_WITHDRAW_ERROR_CLEAR
  };
};
export const approveMMWithdraw = (payload)=>{
  return {
    type: APPROVE_MM_WITHDRAW,
    payload
  };
};
export const rejectMMWithdraw = (payload) =>{
  return {
    type: REJECT_MM_WITHDRAW,
    payload
  };
};