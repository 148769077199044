import {
  FETCH_DEMO_START,
  FETCH_DEMO_SUCCESS,
  FETCH_DEMO_FAILED,
  ADD_DEMO_START
} from "./actionsType";

export const fetchDemoStart = (params = {}) => {
  return {
    type: FETCH_DEMO_START,
    payload: params
  };
};

export const fetchDemoSuccess = (data) => {
  return {
    type: FETCH_DEMO_SUCCESS,
    payload: data
  };
};

export const fetchDemoFailed = (data) => {
  return {
    type: FETCH_DEMO_FAILED,
    payload: data
  };
};

export const addNewDemo = (data) => {
  return {
    type: ADD_DEMO_START,
    payload: data
  };
};