import {
  FETCH_MM_DEPOSITS_REQUESTED,
  FETCH_MM_DEPOSITS_SUCCESS,
  FETCH_MM_DEPOSITS_FAIL,

  ADD_MM_DEPOSIT_REQUESTED,
  ADD_MM_DEPOSIT_SUCCESS,
  ADD_MM_DEPOSIT_FAIL,
  ADD_MM_DEPOSIT_CLEAR,
  ADD_MM_DEPOSIT_ERROR_CLEAR,
  APPROVE_MM_DEPOSIT,
  REJECT_MM_DEPOSIT,

  FETCH_CLIENT_SUBSCRIPTIONS_REQUESTED,
  FETCH_CLIENT_SUBSCRIPTIONS_SUCCESS,
} from "./actionTypes";


// fetch mm deposits
export const fetchMMDeposits = (params = {}) => {
  return {
    type: FETCH_MM_DEPOSITS_REQUESTED,
    payload: params
  };
};
export const fetchClientSubscriptions = (params = {}) => {
  return {
    type: FETCH_CLIENT_SUBSCRIPTIONS_REQUESTED,
    payload: params
  };
};
export const fetchMMDepositsSuccess = (data) => {
  return {
    type: FETCH_MM_DEPOSITS_SUCCESS,
    payload: data
  };
};
export const fetchClientSubscriptionsSuccess = (data) => {
  return {
    type: FETCH_CLIENT_SUBSCRIPTIONS_SUCCESS,
    payload: data
  };
};
export const fetchMMDepositsFail = (error) => {
  return {
    type: FETCH_MM_DEPOSITS_FAIL,
    payload: { error }
  };
};

export const addMMDeposit = (params = {}) => {
  return {
    type: ADD_MM_DEPOSIT_REQUESTED,
    payload: params
  };
};
export const addMMDepositSuccess = (data) => {
  return {
    type: ADD_MM_DEPOSIT_SUCCESS,
    payload: data
  };
};
export const addMMDepositFail = (error) => {
  return {
    type: ADD_MM_DEPOSIT_FAIL,
    payload: { error }
  };
};
export const addMMDepositClear = (data) => {
  return {
    type: ADD_MM_DEPOSIT_CLEAR,
    payload: data
  };
};
export const addMMDepositErrorClear = () => {
  return {
    type: ADD_MM_DEPOSIT_ERROR_CLEAR
  };
};
export const approveMMDeposit = (payload)=>{
  return {
    type: APPROVE_MM_DEPOSIT,
    payload
  };
};
export const rejectMMDeposit = (payload) =>{
  return {
    type: REJECT_MM_DEPOSIT,
    payload
  };
};