import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";

function TransactionModal({ show, toggle, onSubmit, showAmount, title, data, submitButton, ...props }) {
  return (<>
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {props.t(title)}
      </ModalHeader>
      <ModalBody>
        <AvForm
          className='p-4'
          onValidSubmit={(e, v) => {
            data.amount = v.amount ? parseFloat(v.amount) : data.amount;
            data.note = v.note;
            onSubmit(data);
          }}
        >
          {showAmount && <AvField
            name="amount"
            label={props.t("Amount")}
            placeholder={props.t("Enter Amount")}
            type="number"
            errorMessage={props.t("Enter Valid Amount")}
            validate = {{
              required: { value: true }
            }}
            defaultValue={data.amount.$numberDecimal ?? data.amount}
          />}
          <AvField
            name="note"
            label={props.t("Note")}
            placeholder={props.t("Enter Note")}
            type="text"
            errorMessage={props.t("Enter Valid Note")}
            validate = {{
              required: { value: true }
            }}
            defaultValue={data.note}
          />
          <div className='text-center'>
            <Button type="submit" color="primary" className="mx-2">
              {submitButton}
            </Button>
            <Button color="primary" className="mx-2" onClick={toggle}>
                Cancel
            </Button> 
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  </>);
}

export default TransactionModal;