/* eslint-disable no-debugger */

import {
  call, put, takeEvery, delay
} from "redux-saga/effects";
import {
  fetchDemoFromAPI,
  addDemoAPI
} from "../../apis/demo-api";
import {
  fetchDemoSuccess,
  fetchDemoFailed,
} from "./actions";
import {
  ADD_DEMO_CLEAR,
  ADD_DEMO_FAILED,
  ADD_DEMO_START,
  ADD_DEMO_SUCCESS,
  FETCH_DEMO_START,
} from "./actionsType";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function* fetchDemo(params) {
  try {
    const data = yield call(fetchDemoFromAPI, params);
    yield put(fetchDemoSuccess(data));
  } catch (error) {
    yield put(fetchDemoFailed(error));
  }
}

function* addNewDemo(params) {
  try {
    const data = yield call(addDemoAPI, {
      ...params.payload,
    });
    yield put(showSuccessNotification("New Demo Created Successfully"));
    yield put({
      type: ADD_DEMO_SUCCESS,
      payload: data.result
    });
    yield delay(1000);
    yield put({
      type: ADD_DEMO_CLEAR,
      payload: data.result
    });
  } catch (error) {
    yield put(showErrorNotification("Creating New Demo Failed"));
    debugger;
    yield put({
      type: ADD_DEMO_FAILED,
      payload: error
    });
  }
}

function* demoSaga() {
  yield takeEvery(FETCH_DEMO_START, fetchDemo);
  yield takeEvery(ADD_DEMO_START, addNewDemo);
}

export default demoSaga;