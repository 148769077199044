export const FETCH_TRANSACTION_FEE_GROUPS_START = "FETCH_TRANSACTION_FEE_GROUPS_START";
export const FETCH_TRANSACTION_FEE_GROUPS_SUCCESS = "FETCH_TRANSACTION_FEE_GROUPS_SUCCESS";
export const API_ERROR = "API_ERROR";
export const ADD_TRANSACTION_FEES_GROUPS_START = "ADD_TRANSACTION_FEES_GROUPS_START";
export const ADD_TRANSACTION_FEES_GROUP_SUCCESS = "ADD_TRANSACTION_FEES_GROUP_SUCCESS";
export const EDIT_TRANSACTION_FEE_GROUP_START = "EDIT_TRANSACTION_FEE_GROUP_START";
export const EDIT_TRANSACTION_FEE_GROUP_SUCCESS = "EDIT_TRANSACTION_FEE_GROUP_SUCCESS";
export const DELETE_TRANSACTION_FEE_GROUP_START = "DELETE_TRANSACTION_FEE_GROUP_START";
export const DELETE_TRANSACTION_FEE_GROUP_SUCCESS = "DELETE_TRANSACTION_FEE_GROUP_SUCCESS";
export const ADD_MODAL_CLEAR = "ADD_MODAL_CLEAR";
export const EDIT_MODAL_CLEAR = "EDIT_MODAL_CLEAR";
export const DELETE_MODAL_CLEAR = "DELETE_MODAL_CLEAR";