import useModal from "hooks/useModal";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button, Col, Row
} from "reactstrap";
import EditSubIbModal from "./EditSubIbModal";
import ToolTipData from "components/Common/ToolTipData";

function AgreementItem(props) {
  const {
    member, customer, accountTypes = [], products = {},
    isEditAvailable = false, isAgreementValid = false, setIsAgreementValid,
    totals, onDelete
  } = props;
  const { t } = useTranslation();
  const [showEdit, toggleEdit] = useModal();
  return (
    <Row className="my-2" style={{
      backgroundColor: isAgreementValid ? "#e6f7ff" : "#fff"
    }} id={`agreement-item-${member?.customerId}`}
    >
      <Col className="p-1 px-3 shadow border rounded " >
        <Row className="d-flex justify-content-between">
          <Col className="d-flex align-items-center">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-light">
                <i className="bx bxs-user-circle font-size-24 text-primary"></i>
              </span>
            </div>
            <div>
              <h3 className="font-size-14 mb-1">{customer?.firstName} {customer?.lastName}</h3>
              <p className="text-muted mb-0">{customer?.email}</p>
              <p className="text-muted mb-0">{t("Level")} : {member?.level}</p>
            </div>
          </Col>
          <Col>
            {/* show rebate and commission used if valid */}
          </Col>
          <Col>
            <Row>
              <Button
                color="primary"
                className="btn-sm"
                type="button"
                onClick={toggleEdit}
                disabled={!isEditAvailable}
              >
                <i className="mdi mdi-pencil font-size-16"></i>
              </Button>
              <Button
                type="button"
                color="danger my-1"
                className="btn-sm my-1"
                disabled={member?.level === 0}
                onClick={() => onDelete(member?.customerId)}
              >
                <i className="mdi mdi-trash-can font-size-16"></i>
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
      {
        showEdit && (
          <EditSubIbModal
            totals={totals}
            show={showEdit}
            toggle={toggleEdit}
            member={member}
            customer={customer}
            accountTypes={accountTypes}
            products={products}
            setIsAgreementValid={setIsAgreementValid}
          />
        )
      }
      {!isAgreementValid && (
        <ToolTipData
          target={`agreement-item-${member?.customerId}`}
          placement="top"
          data={t("Agreement is invalid")}
        />
      )}
    </Row>
  );
}

export default React.memo(AgreementItem);