import { editDeliveryFees } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { AvField } from "availity-reactstrap-validation";
import { fetchAssetsStart } from "store/assests/actions";
import SharedModal from "pages/GoldPrices/SharedModal";

export default function ProductEditModal(props) {
  const { assets } = useSelector((state) => state.assetReducer);
  const { isOpen, toggle, details } = props;
  const dispatch = useDispatch();
  const handleEdit = (e, values = {}) => {
    dispatch(editDeliveryFees(
      {
        ...values,
        fees: parseInt(values?.fees),
        id: details?._id,
      }
    ));
  };

  useEffect(()=>{
    loadProductTypes(1, 100);
  }, []);
  
  const loadProductTypes = ( page, limit) => {
    dispatch(fetchAssetsStart({
      limit,
      page,
    })) ;
  };

  const fields = [
    <>
      <div className="mb-3">
        <AvField
          name="title"
          label="Title"
          placeholder="Enter Title"
          type="text"
          value={details.title}
          errorMessage="Enter Title"
          validate={{ required: { value: true } }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvField
          name="fees"
          label="Fees"
          placeholder="Enter Fees"
          type="number"
          errorMessage="Enter Fees"
          value={details.fees}
          validate={{ 
            required: { value: true },
            min: {
              value: 1,
              errorMessage: "Fees must be greater than 0" 
            }
          }}
        />
      </div>
    </>,
    <>
      <div className="mb-3">
        <AvFieldSelect
          name="currency"
          label="Currency"
          placeholder="Select Currency"
          value={details.currency}
          options={(assets)?.filter(asset => !asset?.isInventory)?.map((key) => ({
            value: key?.symbol,
            label: key?.symbol,
          }))}
          errorMessage="Select Currency"
          validate={{ required: { value: true } }}
        />
      </div>
    </>,
  ];

  return (
    <SharedModal
      showButton={false}
      isOpen={isOpen}
      toggle={toggle}
      title="Edit Delivery Fees"
      fields={fields}
      submitHandler={handleEdit}
      clearingCounter={0}
      buttonTitle={"Edit"}
    />
  );
}
