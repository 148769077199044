// fetch
// the REQUESTED action type is just a part of a pattern in redux saga 
// it just means that an action was called but didn't return a value yet
// just like a promise REQUESTED = pending, SUCCESS = resolved, FAIL = rejected
export const FETCH_SYSTEM_EMAILS_REQUESTED = "FETCH_SYSTEM_EMAILS_REQUESTED";
export const FETCH_SYSTEM_EMAILS_SUCCESS = "FETCH_SYSTEM_EMAILS_SUCCESS";
export const FETCH_SYSTEM_EMAILS_FAIL = "FETCH_SYSTEM_EMAILS_FAIL";

// fetch by id
export const FETCH_SYSTEM_EMAIL_BY_ID_REQUESTED = "FETCH_SYSTEM_EMAIL_BY_ID";
export const FETCH_SYSTEM_EMAIL_BY_ID_SUCCESS = "FETCH_SYSTEM_EMAIL_BY_ID_SUCCESS";
export const FETCH_SYSTEM_EMAIL_BY_ID_FAIL = "FETCH_SYSTEM_EMAIL_BY_ID_FAIL";
export const FETCH_SYSTEM_EMAIL_BY_ID_CLEAR = "FETCH_SYSTEM_EMAIL_BY_ID_CLEAR";

// add
export const ADD_SYSTEM_EMAIL_REQUESTED = "ADD_SYSTEM_EMAIL_REQUESTED";
export const ADD_SYSTEM_EMAIL_SUCCESS = "ADD_SYSTEM_EMAIL_SUCCESS";
export const ADD_SYSTEM_EMAIL_FAIL = "ADD_SYSTEM_EMAIL_FAIL";
export const ADD_SYSTEM_EMAIL_CLEAR = "ADD_SYSTEM_EMAIL_CLEAR";

// delete
export const DELETE_SYSTEM_EMAIL_REQUESTED = "DELETE_SYSTEM_EMAIL_REQUESTED";
export const DELETE_SYSTEM_EMAIL_SUCCESS = "DELETE_SYSTEM_EMAIL_SUCCESS";
export const DELETE_SYSTEM_EMAIL_FAIL = "DELETE_SYSTEM_EMAIL_FAIL";

// edit 
export const EDIT_SYSTEM_EMAIL_REQUESTED = "EDIT_SYSTEM_EMAIL_REQUESTED";
export const EDIT_SYSTEM_EMAIL_SUCCESS = "EDIT_SYSTEM_EMAIL_SUCCESS";
export const EDIT_SYSTEM_EMAIL_FAIL = "EDIT_SYSTEM_EMAIL_FAIL";
export const EDIT_SYSTEM_EMAIL_CLEAR = "EDIT_SYSTE_EMAIL_CLEAR";

// edit content
export const EDIT_SYSTEM_EMAIL_CONTENT_REQUESTED = "EDIT_SYSTEM_EMAIL_CONTENT_REQUESTED";
export const EDIT_SYSTEM_EMAIL_CONTENT_SUCCESS = "EDIT_SYSTEM_EMAIL_CONTENT_SUCCESS";
export const EDIT_SYSTEM_EMAIL_CONTENT_FAIL = "EDIT_SYSTEM_EMAIL_CONTENT_FAIL";
export const EDIT_SYSTEM_EMAIL_CONTENT_CLEAR = "EDIT_SYSTEM_EMAIL_CONTENT_CLEAR";

// fetch system email HTML
export const FETCH_SYSTEM_EMAIL_HTML_REQUESTED = "FETCH_SYSTEM_EMAIL_HTML_REQUESTED";
export const FETCH_SYSTEM_EMAIL_HTML_SUCCESS = "FETCH_SYSTEM_EMAIL_HTML_SUCCESS";
export const FETCH_SYSTEM_EMAIL_HTML_FAIL = "FETCH_SYSTEM_EMAIL_HTML_FAIL";

// change system email status
export const CHANGE_SYSTEM_EMAIL_STATUS_REQUESTED = "CHANGE_SYSTEM_EMAIL_STATUS_REQUESTED";
export const CHANGE_SYSTEM_EMAIL_STATUS_DONE = "CHANGE_SYSTEM_EMAIL_STATUS_DONE";