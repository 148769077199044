import {
  FETCH_GOLD_TRANSFER_ERROR,
  FETCH_GOLD_TRANSFER_START,
  FETCH_GOLD_TRANSFER_SUCCESS,
  APPROVE_GOLD_TRANSFER_ERROR,
  APPROVE_GOLD_TRANSFER_START,
  APPROVE_GOLD_TRANSFER_SUCCESS,
  REJECT_GOLD_TRANSFER_ERROR,
  REJECT_GOLD_TRANSFER_START,
  REJECT_GOLD_TRANSFER_SUCCESS
} from "./actionTypes";

export const fetchGoldTransfer = (data) => {
  return {
    type: FETCH_GOLD_TRANSFER_START,
    payload: data,
  };
};

export const fetchGoldTransferSuccess = (data) => {
  return {
    type: FETCH_GOLD_TRANSFER_SUCCESS,
    payload: data,
  };
};

export const fetchGoldTransferError = (error) => {
  return {
    type: FETCH_GOLD_TRANSFER_ERROR,
    payload: error,
  };
};

export const approveGoldTransfer = (data) => {
  return {
    type: APPROVE_GOLD_TRANSFER_START,
    payload: data,
  };
};

export const approveGoldTransferSuccess = (data) => {
  return {
    type: APPROVE_GOLD_TRANSFER_SUCCESS,
    payload: data,
  };
};

export const approveGoldTransferError = (error) => {
  return {
    type: APPROVE_GOLD_TRANSFER_ERROR,
    payload: error,
  };
};

export const rejectGoldTransfer = (data) => {
  return {
    type: REJECT_GOLD_TRANSFER_START,
    payload: data,
  };
};

export const rejectGoldTransferSuccess = (data) => {
  return {
    type: REJECT_GOLD_TRANSFER_SUCCESS,
    payload: data,
  };
};

export const rejectGoldTransferError = (error) => {
  return {
    type: REJECT_GOLD_TRANSFER_ERROR,
    payload: error,
  };
};