/* eslint-disable no-debugger */
import {
  FETCH_DEMO_START, FETCH_DEMO_SUCCESS, ADD_DEMO_SUCCESS, ADD_DEMO_START, ADD_DEMO_FAILED,
  ADD_DEMO_CLEAR
} from "./actionsType";

const initialState = {
  loading: false,
  excelLoading: false,
  updateCallStatusLoading: false,
  error: "",
  successMessage: "",
  clients: [],
  totalDocs: 0,
  addClearingCounter: 0,
  addNewLoading: false,
  addNewDone: false,
};
const demoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEMO_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_DEMO_SUCCESS:
      state = {
        ...state,
        clients: [...action.payload.result.docs],
        totalDocs: action.payload.result.totalDocs,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
        loading: false
      };
      break;
    case ADD_DEMO_START:
      state = {
        ...state,
        addNewLoading: true,
      };
      break;
    case ADD_DEMO_SUCCESS:
      state = {
        ...state,
        totalDocs: state.totalDocs + 1,
        addNewLoading: false,
        addNewDone: true,
      };
      state.clients.unshift({ ...action.payload });
      break;
    case ADD_DEMO_FAILED:
      state = {
        ...state,
        addNewLoading: false,
      };
      break;
    case ADD_DEMO_CLEAR:
      state = {
        ...state,
        addNewDone: false
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default demoReducer;