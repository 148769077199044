import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector,
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "components/Common/TableLoader";
import { convertToInvestor, fetchClientDetails } from "store/client/actions";

function ConvertToInvestor({ isLead, kyc = true, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    loading, clearingCounter,
  } = useSelector(state => state.clientReducer.convertToInvestor);

  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow(!show);
  };

  const handleConfirm = () => {
    dispatch(convertToInvestor({ id }));
  };

  useEffect(() => {
    if (show) {
      dispatch(fetchClientDetails(id));
    }
  }, [clearingCounter]);

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("Convert To Investor")}
      </button>
      <Modal isOpen={show} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>
          {t("Convert Client To Investor")}
        </ModalHeader>
        <ModalBody >
          <Container>
            <Row className="mb-3">
              {
                kyc 
                  ? <Col md="12">
                    <h5>{t("Are you sure you want to convert this client to an Investor?")}</h5>
                  </Col> : <Col md="12">
                    <p>{t("Please approve the kyc before converting the client to Investor!")}</p>
                  </Col>
              }
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} color="secondary">
            {t("No")}
          </Button>
          { loading ? <Loader /> : (
            <Button onClick={handleConfirm} color="primary" disabled={!kyc}>
              {t("Yes")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment >
  );
}

export default (ConvertToInvestor);