import { getClients } from "apis/client";
import { AvForm } from "availity-reactstrap-validation";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import React from "react";
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from "reactstrap";

export default function AddSubIbModal(props) {
  const {
    show, toggle, disallow = [], addIb
  } = props;
  const [subIb, setSubIb] = React.useState(null);
  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered
    >
      <AvForm
        onValidSubmit={() => {}}
      >
        <ModalHeader
          toggle={toggle}
        >
            Add Sub IB
        </ModalHeader>
        <ModalBody>
          <Row>
            <SearchableComponent
              isRequired
              label="IB"
              name={"subIb"}
              placeholder="Search IB"
              onChange={(e) => {
                e && setSubIb(e?.value);
              }}
              getData={async (payload) => getClients({
                payload: {
                  fxType: "IB",
                  parentId: null,
                  ...payload
                }
              })?.then((res) => (res?.result?.docs?.filter(
                (doc) => !disallow.includes(doc._id)
              ) || []))}
              mapper={(doc)=> (
                {
                  label: `${doc.firstName} ${doc.lastName}`,
                  value: doc
                }
              ) || []}
              value={subIb}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={toggle}
            className="btn btn-secondary waves-effect"
          >
            Close
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={!subIb}
            className="btn btn-primary waves-effect waves-light"
            onClick={()=>{
              addIb(subIb);
              toggle();
            }}
          >
            Add IB
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
