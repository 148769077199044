

export const FETCH_WALLET_TRANSFER_START = "FETCH_WALLET_TRANSFER_START";
export const FETCH_WALLET_TRANSFER_SUCCESS = "FETCH_WALLET_TRANSFER_SUCCESS";
export const FETCH_WALLET_TRANSFER_ERROR = "FETCH_WALLET_TRANSFER_ERROR";

export const APPROVE_WALLET_TRANSFER_START = "APPROVE_WALLET_TRANSFER_START";
export const APPROVE_WALLET_TRANSFER_SUCCESS = "APPROVE_WALLET_TRANSFER_SUCCESS";
export const APPROVE_WALLET_TRANSFER_ERROR = "APPROVE_WALLET_TRANSFER_ERROR";

export const REJECT_WALLET_TRANSFER_START = "REJECT_WALLET_TRANSFER_START";
export const REJECT_WALLET_TRANSFER_SUCCESS = "REJECT_WALLET_TRANSFER_SUCCESS";
export const REJECT_WALLET_TRANSFER_ERROR = "REJECT_WALLET_TRANSFER_ERROR";
