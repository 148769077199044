import {
  FETCH_PRODUCTS_START,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  ADD_PRODUCT_START,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_CLEAR,
  EDIT_PRODUCT_START,
  EDIT_PRODUCT_CLEAR,
  TOGGLE_PRODUCT_START,
  TOGGLE_PRODUCT_SUCCESS,
  TOGGLE_PRODUCT_ERROR,
  TOGGLE_PRODUCT_CP_START,
  TOGGLE_PRODUCT_CP_SUCCESS,
  TOGGLE_PRODUCT_CP_ERROR,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  products: [],
  clearingCounter: 0,
  // totalDocs: 0,
  // docs: [],
  // page: 1
};
const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_START:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FETCH_PRODUCTS_SUCCESS:
      state = {
        ...state,
        products: [...action.payload.docs],
        totalDocs: action.payload.totalDocs,
        hasNextPage: action.payload.hasNextPage,
        hasPrevPage: action.payload.hasPrevPage,
        limit: action.payload.limit,
        nextPage: action.payload.nextPage,
        page: action.payload.page,
        pagingCounter: action.payload.pagingCounter,
        prevPage: action.payload.prevPage,
        totalPages: action.payload.totalPages,
        loading: false,
      };
      break;
    case FETCH_PRODUCTS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case ADD_PRODUCT_START:
      state = {
        ...state,
        addLoading: true,
      };
      break;
    case ADD_PRODUCT_SUCCESS:
      state = {
        ...state,
        addResult: action.payload,
        products: [action.payload, ...state.products],
        addSuccess: true,
        addError: false,
        addLoading: false,
      };
      break;
    case ADD_PRODUCT_ERROR:
      state = {
        ...state,
        addLoading: false,
        addErrorDetails: action.payload,
        addSuccess: false,
        addError: true,
      };
      break;
    case ADD_PRODUCT_CLEAR:
      state = {
        ...state,
        addErrorDetails: "",
        addSuccess: false,
        addError: false,
        addResult: null,
        clearingCounter: state.clearingCounter + 1,
      };
      break;

    case EDIT_PRODUCT_START:
      state = {
        ...state,
        editLoading: true,
      };
      break;
    case EDIT_PRODUCT_CLEAR:
      state = {
        ...state,
        editResult: null,
        editError: null,
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case TOGGLE_PRODUCT_START:
    case TOGGLE_PRODUCT_CP_START:
      state = {
        ...state,
        TOGGLELoading: true,
      };
      break;
    case TOGGLE_PRODUCT_SUCCESS:
    case TOGGLE_PRODUCT_CP_SUCCESS:
      state = {
        ...state,
        TOGGLELoading: false,
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case TOGGLE_PRODUCT_ERROR:
    case TOGGLE_PRODUCT_CP_ERROR:
      state = {
        ...state,
        TOGGLELoading: false,
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default leadReducer;
