import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";

const AccountDetailsCol = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <Col xs={12} xl={6} className="gap-2 d-flex flex-column py-2 py-xl-4">
      <b>{t(label)}</b>
      <div className="p-2 border border-dark">{value}</div>
    </Col>
  );
};

export default AccountDetailsCol;
