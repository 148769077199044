import * as axiosHelper from "./api_helper";
import qs from "qs";

// fetch forex deposits
export const getMMDeposits = async ({ payload }) => {
  const result = await axiosHelper.get(`/mmtransactions/deposit?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

// add forex deposit
export const postMMDeposit = async ({ payload }) => {
  const result = await axiosHelper.post("/mmtransactions/deposit", payload);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const approveMMDeposit = async (id, customerId) => {
  const result = await axiosHelper.patch(`/mmtransactions/deposit/${id}/approve`, { customerId });
  if (result.isError){
    throw new Error(result.message);
  }
  return result;
};


export const rejectMMDeposit = async (id, customerId) => {
  const result = await axiosHelper.patch(`/mmtransactions/deposit/${id}/reject`, { customerId });
  if (result.isError){
    throw new Error(result.message);
  }
  return result;
};
