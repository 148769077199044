import { fetchMamPamAPI } from "apis/mam-profile";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import React from "react";

const getProfileType = ( profileType ) => {
  switch (profileType) {
    case 1: 
      return "MAM";
    case 2:
      return "PAM";
    default:
      return "";
  }
};

export default function SearchableProfile({
  customerId = null, profile, setProfile
}) {
  return (
    <SearchableComponent
      placeholder={("Select Profile")}
      label={"Profile"}
      name={"profile"}
      onChange={(e) => {
        e?.value ? setProfile(e.value) : setProfile(e);
      }}
      getData={
        async () => fetchMamPamAPI({ 
          page: 1,
          limit: 1000,
          customerId,
        }).then((res) => (res?.docs || []))
      }
      mapper={(doc)=> (
        {
          label: `${getProfileType(doc.profileType)} ${doc.platform} | ${doc?.masterTradingAccountId?.login} | ${doc?.slaves?.length} Members `,
          value: doc
        }
      )}
      shouldReset={customerId}
      value={profile}
    />
  );
}
