import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import StatementTable from "./StatementTable";
import ReactSelect from "react-select";
import { useSelector } from "react-redux";
import SPStatement from "./SPStatement";
import CustomSelect from "components/Common/CustomSelect";

const OPTIONS = [
  {
    value: "IB",
    label: "IB",
  },
  { 
    value: "SP",
    label: "SP",
  },
];

const Statement = () => {
  const {
    t
  } = useTranslation();
  const { _id, referrals, fx, stages, } = useSelector(
    (state) => state.clientReducer?.clientDetails
  );

  const options = OPTIONS.filter((obj)=>{
    if (obj.value === "IB" && fx?.isIb) {
      return true;
    }
    if (obj.value === "SP" && fx?.isSp && stages?.sp?.configureProfile) {
      return true;
    }
    return false;
  }
  );

  const [type, setType] = useState(options[0]?.value);

  return (
    <Container fluid className="gx-4">
      <div className="row m-2">
        <label>{t("Statement For")}</label>
        <CustomSelect
          className="w-full"
          name="referral"
          onChange={(e) => {
            setType(e.value);
          }}
          value={{
            value: type,
            label: type,
          }}
          options={options}
        />
      </div>
      {type === "IB" && <StatementTable />}
      {type === "SP" && <SPStatement />}
    </Container >
  );
};

export default withTranslation()(Statement);
