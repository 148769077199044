export const FETCH_MARKETS_START = "FETCH_MARKETS_START";
export const FETCH_MARKETS_SUCCESS = "FETCH_MARKETS_SUCCESS";
export const API_ERROR = "API_ERROR";
export const ADD_NEW_MARKET = "ADD_NEW_MARKET";
export const ADD_NEW_MARKET_SUCCESS = "ADD_NEW_MARKET_SUCCESS";
export const ADD_MARKET_CLEAR = "ADD_MARKET_CLEAR ";
export const EDIT_MARKET_START = "EDIT_MARKET_START";
export const EDIT_MARKET_SUCCESS = "EDIT_MARKET_SUCCESS";
export const EDIT_MARKET_CLEAR = "EDIT_MARKET_CLEAR";
export const ADD_MARKET_ERROR = "ADD_MARKET_ERROR";

// change market status (active, inactive)
export const CHANGE_MARKET_STATUS_REQUESTED = "CHANGE_MARKET_STATUS_REQUESTED";
export const CHANGE_MARKET_STATUS_DONE = "CHANGE_MARKET_STATUS_DONE";