
export const FETCH_GOLD_TRANSFER_START = "FETCH_GOLD_TRANSFER_START";
export const FETCH_GOLD_TRANSFER_SUCCESS = "FETCH_GOLD_TRANSFER_SUCCESS";
export const FETCH_GOLD_TRANSFER_ERROR = "FETCH_GOLD_TRANSFER_ERROR";

export const APPROVE_GOLD_TRANSFER_START = "APPROVE_GOLD_TRANSFER_START";
export const APPROVE_GOLD_TRANSFER_SUCCESS = "APPROVE_GOLD_TRANSFER_SUCCESS";
export const APPROVE_GOLD_TRANSFER_ERROR = "APPROVE_GOLD_TRANSFER_ERROR";

export const REJECT_GOLD_TRANSFER_START = "REJECT_GOLD_TRANSFER_START";
export const REJECT_GOLD_TRANSFER_SUCCESS = "REJECT_GOLD_TRANSFER_SUCCESS";
export const REJECT_GOLD_TRANSFER_ERROR = "REJECT_GOLD_TRANSFER_ERROR";
