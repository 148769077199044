import {
  call,
  put,
  takeEvery
} from "redux-saga/effects";
import {
  approveGoldTransferError,
  approveGoldTransferSuccess,
  fetchGoldTransferError,
  fetchGoldTransferSuccess,
  rejectGoldTransferError,
  rejectGoldTransferSuccess
} from "./actions";
import {
  APPROVE_GOLD_TRANSFER_START,
  FETCH_GOLD_TRANSFER_START,
  REJECT_GOLD_TRANSFER_START
} from "./actionTypes";
import {
  approveGold,
  getGoldTransfers,
  rejectGold
} from "apis/gold";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";

function* fetchGoldTransfer({ payload }) {
  try {
    const response = yield call(getGoldTransfers, payload);
    yield put(fetchGoldTransferSuccess(response));
  } catch (error) {
    yield put(fetchGoldTransferError(error));
  }
}

function* approveGoldTransfer({ payload }) {
  try {
    const response = yield call(approveGold, payload);
    yield put(approveGoldTransferSuccess(response));
    yield put(showSuccessNotification("Wallet Transfer approved successfully"));
  } catch (error) {
    yield put(approveGoldTransferError(error));
    yield put(showErrorNotification("Wallet Transfer approved failed"));
  }
}

function* rejectGoldTransfer({ payload }) {
  try {
    const response = yield call(rejectGold, payload);
    yield put(rejectGoldTransferSuccess(response));
    yield put(showSuccessNotification("Wallet Transfer rejected successfully"));
  } catch (error) {
    yield put(rejectGoldTransferError(error));
    yield put(showErrorNotification("Wallet Transfer rejected failed"));
  }
}

function* transferSaga() {
  yield takeEvery(FETCH_GOLD_TRANSFER_START, fetchGoldTransfer);
  yield takeEvery(APPROVE_GOLD_TRANSFER_START, approveGoldTransfer);
  yield takeEvery(REJECT_GOLD_TRANSFER_START, rejectGoldTransfer);
}

export default transferSaga;
