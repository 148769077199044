import React from "react";

// i18n
import { withTranslation } from "react-i18next";
import MainNavigation from "./MainNavigation";

function Layout({ clientId, isIb, isLead, children, isSp, isInvestor, hasAffiliate = false, hasProfile = false, isDemo = false }) {
  // const onClickHandler = () => {
  //   props.redirectToListingHandler();
  // };

  return (
    <React.Fragment>
      <MainNavigation
        clientId={clientId}
        isIb={isIb}
        isLead={isLead}
        isSp={isSp}
        isInvestor={isInvestor}
        hasAffiliate={hasAffiliate || isSp}
        hasProfile={hasProfile}
        isDemo={isDemo}
      />
      <main>{children}</main>
    </React.Fragment>
  );
}

export default withTranslation()(Layout);
