
export const FETCH_ROLES_START = "FETCH_ROLES_START";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_ERROR = "FETCH_ROLES_ERROR";

export const ADD_ROLES_START = "ADD_ROLES_START";
export const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS";
export const ADD_ROLES_ERROR = "ADD_ROLES_ERROR";
export const ADD_ROLE_CLEAR = "ADD_ROLE_CLEAR";


export const EDIT_ROLES_START = "EDIT_ROLES_START";
export const EDIT_ROLES_DONE = "EDIT_ROLES_DONE";
export const EDIT_ROLE_CLEAR = "EDIT_ROLE_CLEAR";

export const DELETE_ROLES_START = "DELETE_ROLES_START";
export const DELETE_ROLES_DONE =  "DELETE_ROLES_DONE";

export const CHANGE_STATUS_ROLES_START = "CHANGE_STATUS_ROLES_START";
export const CHANGE_STATUS_ROLES_END = "CHANGE_STATUS_ROLES_END";
