import { getOpenPositionsAPI } from "apis/tradingAccounts";
import TableLoader from "components/Common/Loader";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line object-curly-newline
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Card } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import formatDate from "helpers/formatDate";
import SkeletonTableLoader from "components/Loaders/SkeletonTableLoader";

const MasterAccountPositions = ({ masterTradingAccountId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);

  const loadPositions = async () => {
    setLoading(true);
    getOpenPositionsAPI({
      _id: masterTradingAccountId,
      page: 1,
      limit: 100,
    }).then((res) => {
      if (res?.result) {
        setPositions(res.result.docs);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (masterTradingAccountId) {
      loadPositions();
    }
  }, [masterTradingAccountId]);

  return (
    <div>
      <h3 className="text-center">
        {t("Master Account Positions")}
        <FeatherIcon
          icon="refresh-cw"
          className="icon-lg ms-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            loadPositions();
          }}
        />
      </h3>
      <Card className="p-3 mt-4">
        <Table id="tech-companies-1"
          className="table">
          <Thead className="text-center table-light">
            <Tr>
              <Th className="py-2 color-primary text-start">{t("Ticket")}</Th>
              <Th className="py-2 color-primary text-start">{t("Action")}</Th>
              <Th className="py-2 color-primary text-start">{t("Symbol")}</Th>
              <Th className="py-2 color-primary">{t("Volume")}</Th>
              <Th className="py-2 color-primary">{t("Price")}</Th>
              <Th className="py-2 color-primary">{t("Price SL")}</Th>
              <Th className="py-2 color-primary">{t("Price TP")}</Th>
              <Th className="py-2 color-primary">{t("Price Position")}</Th>
              <Th className="py-2 color-primary">{t("Profit")}</Th>
              <Th className="py-2 color-primary">{t("Time")}</Th>
            </Tr>
          </Thead>
          <Tbody className="text-center">
            {
              loading ? <SkeletonTableLoader rows={3} columns={10} /> : (
                positions?.length > 0 ? (
                  positions.map((position) => (
                    <Tr key={position._id}>
                      <Td className="text-start">{position.Position ?? "-"}</Td>
                      <Td className="text-start">{position?.Action === "0" ? t("Buy") : t("Sell") ?? "-"}</Td>
                      <Td className="text-start">{position.Symbol ?? "-"}</Td>
                      <Td>{parseInt(position.Volume) / 10000 ?? "-"}</Td>
                      <Td>{position.PriceOpen ?? "-"}</Td>
                      <Td>{position.PriceSL ?? "-"}</Td>
                      <Td>{position.PriceTP ?? "-"}</Td>
                      <Td>{position.PriceCurrent ?? "-"}</Td>
                      <Td>{position.Profit ?? "-"}</Td>
                      <Td>{formatDate(position?.TimeCreate * 1000) ?? "-"}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="9 color-primary">{t("No master account positions found.")}</Td>
                  </Tr>
                )
              )
            }
          </Tbody>
        </Table>
      </Card>
    </div>
  );
};

export default MasterAccountPositions;
