import {
  FETCH_TRANSACTIONS_ERROR,
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS
} from "./actionTypes";

export const fetchTransactionsStart = (params = {}) => {
  return {
    type: FETCH_TRANSACTIONS_START,
    payload: params,
  };
};

export const fetchTransactionsSuccess = (data) => {
  return {
    type: FETCH_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchTransactionsError = (error) => {
  return {
    type: FETCH_TRANSACTIONS_ERROR,
    payload: { error },
  };
};
