export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const EDIT_PROFILE_SETTINGS_START = "EDIT_PROFILE_SETTINGS_START";
export const EDIT_PROFILE_SETTINGS_SUCCESS = "EDIT_PROFILE_SETTINGS_SUCCESS";
export const EDIT_PROFILE_SETTINGS_FAIL = "EDIT_PROFILE_SETTINGS_FAIL";

export const UPDATE_PUSH_NOTIFICATION_OPTION = "UPDATE_PUSH_NOTIFICATION_OPTION";
// email config
export const SAVE_USER_EMAIL_CONFIGURATION = "SAVE_USER_EMAIL_CONFIGURATION";
export const SAVE_USER_EMAIL_CONFIGURATION_SUCCESS = "SAVE_USER_EMAIL_CONFIGURATION_SUCCESS";
export const SAVE_USER_EMAIL_CONFIGURATION_FAIL = "SAVE_USER_EMAIL_CONFIGURATION_FAIL";

export const CHANGE_ACTIVE_USER_EMAIL_CONFIGURATION = "CHANGE_ACTIVE_USER_EMAIL_CONFIGURATION";
export const CHANGE_ACTIVE_USER_EMAIL_CONFIGURATION_SUCCESS = "CHANGE_ACTIVE_USER_EMAIL_CONFIGURATION_SUCCESS";
export const CHANGE_ACTIVE_USER_EMAIL_CONFIGURATION_FAIL = "CHANGE_ACTIVE_USER_EMAIL_CONFIGURATION_FAIL";

export const TEST_USER_EMAIL_CONFIGURATION = "TEST_USER_EMAIL_CONFIGURATION";
export const TEST_USER_EMAIL_CONFIGURATION_SUCCESS = "TEST_USER_EMAIL_CONFIGURATION_SUCCESS";
export const TEST_USER_EMAIL_CONFIGURATION_FAIL = "TEST_USER_EMAIL_CONFIGURATION_FAIL";
